import React from 'react';

import { Home, Vendors } from '../../exports/exports';
import UserContextProvider from '../../context/user-context';
import VendorsContextProvider from '../../context/vendors-context';

const VendorsPage = () => {
  return (
    <UserContextProvider>
      <VendorsContextProvider>
        <Home view={<Vendors />} link={'vendors'} />
      </VendorsContextProvider>
    </UserContextProvider>
  );
};

export default VendorsPage;
