import React from 'react';

import { Home, Plans } from '../../exports/exports';
import UserContextProvider from '../../context/user-context';

const PlansPage = () => {
  return (
    <UserContextProvider>
      <Home view={<Plans />} link={'plans'} />
    </UserContextProvider>
  );
};

export default PlansPage;
