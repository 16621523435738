'use client';

import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { motion } from 'framer-motion';
import { BiHide, BiShow } from 'react-icons/bi';

import { Button, Card } from '../../../../exports/exports';
import { images } from '../../../../constants';
import { ResetPasswordSchema } from '../../../../utils/validationSchema';
import './ResetPassword.scss';

interface InitialValues {
  password: string;
  confirm_password: string;
}

const ResetPassword: React.FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const initialValues: InitialValues = { password: '', confirm_password: '' };

  const handleToggle = () => setIsHidden(!isHidden);

  return (
    <main className="app__reset-password">
      <motion.div
        className="app__reset-password-centered"
        key="app__reset-password-centered"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delayChildren: 0.5,
        }}
      >
        <Image src={images.adminLogo} alt="admin-logo" />
        <Card className="app__reset-password-card">
          <h1 className="heading">Reset password</h1>
          <p className="description">
            Make sure your password is safe and secure
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              console.log({ values, actions });
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="form-info">
                  <label htmlFor="password">Password</label>
                  <div>
                    {errors.password && touched.password ? errors.password : ''}
                  </div>
                </div>

                <div className="input-group">
                  <Field
                    type={isHidden ? 'password' : 'text'}
                    name="password"
                    placeholder="******"
                  />
                  {isHidden ? (
                    <BiShow onClick={handleToggle} />
                  ) : (
                    <BiHide onClick={handleToggle} />
                  )}
                </div>

                <div className="form-info">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <div>
                    {errors.confirm_password && touched.confirm_password
                      ? errors.confirm_password
                      : ''}
                  </div>
                </div>

                <div className="input-group">
                  <Field
                    type={isHidden ? 'password' : 'text'}
                    name="confirm_password"
                    placeholder="******"
                  />
                  {isHidden ? (
                    <BiShow onClick={handleToggle} />
                  ) : (
                    <BiHide onClick={handleToggle} />
                  )}
                </div>

                <Button
                  type="submit"
                  btn_style="btn--primary"
                  size="btn--large"
                  color="btn--alpha"
                  disabled={isSubmitting}
                >
                  Proceed
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
      </motion.div>
    </main>
  );
};

export default ResetPassword;
