'use client';

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BsCreditCard2Front, BsBank } from 'react-icons/bs';

import './PaymentMethod.scss';

const PaymentMethod: React.FC = () => {
  const [selected, setSelected] = useState<string>('card');

  const handleCard = (id: string) => {
    setSelected(id);
  };

  const handleBank = (id: string) => {
    setSelected(id);
  };

  return (
    <motion.div
      key="payment-method"
      className="payment-method"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <h3 className="heading">Payment Methods Available</h3>

      <div className="methods">
        <div
          className={`method ${selected === 'card' ? 'active' : ''}`}
          onClick={() => handleCard('card')}
        >
          <BsCreditCard2Front />
          <p>Card/USSD payment</p>
        </div>
        <div
          className={`method ${selected === 'bank' ? 'active' : ''}`}
          onClick={() => handleBank('bank')}
        >
          <BsBank />
          <p>Dynamic bank transfer</p>
        </div>
      </div>
    </motion.div>
  );
};

export default PaymentMethod;
