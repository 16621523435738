'use client';

import React, { useContext } from 'react';
import { motion } from 'framer-motion';

import { AdminContext } from '../../../../context/admin-context';
import AdminGroup from './AdminGroup';
import AccountSettings from './AccountSettings';
import { Welcome } from '../../../../exports/exports';
import './UserManagement.scss';

const UserManagement: React.FC = () => {
  const ctx = useContext(AdminContext);

  const { view } = ctx;

  let content;

  switch (view) {
    case 'group':
      content = <AdminGroup />;
      break;

    case 'settings':
      content = <AccountSettings />;
      break;

    default:
      content = <Welcome />;
      break;
  }

  return (
    <motion.div
      key="user-management"
      className="user-management"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      {content}
    </motion.div>
  );
};

export default UserManagement;
