'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field } from 'formik';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { LuWallet } from 'react-icons/lu';
import { Avatar } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';

import { VendorsContext } from '../../../../context/vendors-context';
import { Card } from '../../../../exports/exports';
import api from '../../../../helpers/api';
import { AddSubscription } from '../../../../types/Subscription';
import { AllPlans } from '../../../../types/Plan';
import { AddSubscriptionSchema } from '../../../../utils/validationSchema';
import { Orders, Revenue, Products } from './Analytics';
import { Transactions, Customers } from './Analytics';
import './VendorProfile.scss';
import { AES } from 'crypto-js';

export function setCookie(cname: string, cvalue: string, exdays: number, domain: string) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domain;
}

export function randomString(length: number) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

interface Status {
  style: string;
  value: string;
}

interface Plan {
  style: string;
  value: string;
}

const initialValues: AddSubscription = { plan_id: '', vendor_id: '' };

const VendorProfile: React.FC = () => {
  const [status, setStatus] = useState<Status>({ style: '', value: '' });
  const [plan, setPlan] = useState<Plan>({ style: '', value: '' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<AddSubscription>(initialValues);
  const [allPlans, setAllPlans] = useState<AllPlans[] | null>(null);

  const ctx = useContext(VendorsContext);

  const { changeVendor, changeView, clearVendor, loading, vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    if (!loading && !vendor) {
      if (toast.isActive('no-vendor')) return;
      toast({
        id: 'no-vendor',
        title: 'No Vendor',
        description: 'Vendor data not found',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });

      return;
    }

    if (!vendor) return;

    setInitValues({ plan_id: '', vendor_id: vendor.id });

    const fetchAllPlans = async () => {
      try {
        const response = await api.get('/plans');

        if (!response) throw new Error('No response from server.');
        if (response.status !== 200) throw new Error('Failed to fetch plans.');

        const data: AllPlans[] = await response.data.data;

        if (!data) throw new Error('Plans not found.');

        setAllPlans(data);
      } catch (err: any) {
        if (toast.isActive('error')) return;
        toast({
          id: 'error',
          title: 'Something went wrong',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchAllPlans();
  }, [loading, toast, vendor]);

  useEffect(() => {
    const handleSubscription = () => {
      if (!loading && !vendor) {
        if (toast.isActive('no-vendor')) return;
        toast({
          id: 'no-vendor',
          title: 'No Vendor',
          description: 'Vendor data not found',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      if (!vendor) return;

      if (!loading && !vendor.subscription) {
        if (toast.isActive('no-subscription')) return;
        toast({
          id: 'no-subscription',
          title: 'No Subscription',
          description: 'Vendor has no subscription',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setStatus({ style: 'inactive', value: 'INACTIVE' });
        setPlan({ style: 'none', value: 'None' });

        return;
      }

      if (!vendor.subscription) return;

      switch (vendor.subscription.status) {
        case 1:
          setStatus({ style: 'active', value: 'ACTIVE' });
          break;

        case 2:
          setStatus({ style: 'blocked', value: 'BLOCKED' });
          break;

        case 3:
          setStatus({ style: 'inactive', value: 'INACTIVE' });
          break;

        case null || undefined:
          setStatus({ style: 'inactive', value: 'INACTIVE' });
          break;

        default:
          setStatus({ style: 'inactive', value: 'INACTIVE' });
          break;
      }

      switch (vendor.subscription.plan.group_name) {
        case 'Starter':
          setPlan({ style: 'starter', value: 'STARTER' });
          break;

        case 'Professional':
          setPlan({ style: 'professional', value: 'PROFESSIONAL' });
          break;

        case 'Premium':
          setPlan({ style: 'premium', value: 'PREMIUM' });
          break;

        case null || undefined:
          setPlan({ style: 'none', value: 'Null' });
          break;

        default:
          setPlan({ style: 'none', value: 'N/A' });
          break;
      }
    };

    handleSubscription();
  }, [loading, toast, vendor]);

  const handleGoBack = () => {
    clearVendor();
    changeView('group');
  };

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const proxyIntoStore = async () => {
    try {

      const response = await api.post('/vendors/proxy', vendor);
      if (response.data.status === 'success') {

        const randomString1 = randomString(56);
        const randomString2 = randomString(56);

        const token = AES.encrypt(`${randomString1}:${response.data.data.token}:${randomString2}`, process.env.REACT_APP_CRYPTO_SECRET_KEY || 'tj_crypt_local_').toString();
        setCookie(process.env.REACT_APP_COOKIE_NAME || '_apuid_dev', token, 100, process.env.REACT_APP_DOMAIN || 'localhost');

        window.open(process.env.REACT_APP_STORE_MANAGER_URL, '_blank');
      }

    } catch (err: any) {
      if (toast.isActive('error')) return;
      toast({
        id: 'error',
        title: 'Something went wrong',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }


  const handleAddSubscription = useCallback(
    async (newPlan: AddSubscription) => {
      try {
        const response = await api.put('/vendors/update/subscription', newPlan); // prettier-ignore

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          throw new Error('Failed to update subscription');
        }

        if (toast.isActive('updated')) return;
        toast({
          id: 'updated',
          title: 'Subscription updated',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        if (!vendor) return;

        changeVendor(vendor.id as number);
        handleCloseModal();
      } catch (err: any) {
        if (toast.isActive('error')) return;
        toast({
          id: 'error',
          title: 'Something went wrong',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [changeVendor, toast, vendor]
  );

  const UpdateSubscriptionModal = (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      motionPreset="scale"
      scrollBehavior="inside"
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Formik
            initialValues={initValues}
            validationSchema={AddSubscriptionSchema}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);

              // TODO: remove side-effect
              console.log(+values.plan_id, values.vendor_id);

              const updatedValues: AddSubscription = {
                plan_id: +values.plan_id,
                vendor_id: +values.vendor_id,
              };

              handleAddSubscription(updatedValues);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="plan_name">Plan ID</label>
                      <div>
                        {errors.plan_id && touched.plan_id
                          ? errors.plan_id
                          : ''}
                      </div>
                    </div>

                    <Field as="select" name="plan_id" className="dropdown">
                      <option value="">Select Plan ID</option>
                      {allPlans &&
                        allPlans.map(plans => {
                          return plans.plans.map((plan, index) => {
                            return (
                              <option
                                key={`${index}-${plan.id}`}
                                value={plan.id}
                              >{`${plan.name} (${plan.group_name})`}</option>
                            );
                          });
                        })}
                    </Field>
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="vendor_id">Vendor ID</label>
                      <div>
                        {errors.vendor_id && touched.vendor_id
                          ? errors.vendor_id
                          : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="vendor_id"
                      placeholder="Ex: 1"
                      disabled
                    />
                  </div>
                </div>

                <ButtonGroup display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    disabled={isSubmitting}
                    width="full"
                    isLoading={isSubmitting}
                    loadingText="Submitting ..."
                  >
                    Update Subscription
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                    width="full"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  return (
    <motion.div
      key="vendor-profile"
      className="vendor-profile"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      {isOpen && UpdateSubscriptionModal}
      {vendor ? (
        <>
          <div className="back-button" onClick={handleGoBack}>
            <MdKeyboardArrowLeft />
          </div>

          <Card className="vendor-summary">
            <div className="vendor-info">
              <div className="image">
                <Avatar
                  name={vendor.name}
                  src={`${process.env.REACT_APP_STORAGE_URL}/stores/${vendor.logo}`}
                  size="md"
                />
              </div>

              <div className="info">
                <div className="info-top">
                  <p>Vendors name</p>
                  <h3>{vendor.user.firstname} {vendor.user.lastname}</h3>
                </div>
                <div className="info-middle">
                  <p>Vendors store name</p>
                  <h3>{vendor.name}</h3>
                </div>
                <div className="info-middle">
                  <p>Vendors Bio</p>
                  <h3>{vendor.description}</h3>
                </div>
                <div className="info-bottom">
                  <div style={{ display: 'flex', gap: 3 }}>
                    <div>
                      <p>Update Subscription</p>
                      <h3 onClick={handleOpenModal}>
                        {`${!vendor.subscription ? 'Add' : 'Update'} Subscription`}
                      </h3>
                    </div>
                    <div>
                      <p>Proxy</p>
                      <h3 onClick={proxyIntoStore}>
                        Proxy Access Store
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wallet-info">
              <div className="image">
                {vendor.wallet.active && <LuWallet />}
              </div>
              <div className="info">
                <p>Wallet balance</p>
                <h3>{`₦${vendor.wallet.balance.toLocaleString()}`}</h3>
              </div>
            </div>
          </Card>

          <div className="vendor-full-summary">
            <div className="store-detail">
              <Card className="header">
                <h1>Store Details</h1>
              </Card>

              <Card className="body">
                <div className="section__one">
                  <div className="info">
                    <p>Email</p>
                    <h3>{vendor.user.email}</h3>
                  </div>

                  <div className="info">
                    <p>Phone Number</p>
                    <h3>{vendor.phone || vendor.user.phone}</h3>
                  </div>
                </div>

                <div className="section__two">
                  <div className="info">
                    <p>Username</p>
                    <h3>{vendor.user.email}</h3>
                  </div>

                  <div className="info">
                    <p>Status</p>
                    <h3 className={`status ${status.style}`}>{status.value}</h3>
                  </div>
                </div>

                <div className="section__three">
                  <div className="info">
                    <p>User Id</p>
                    <h3>{vendor.id}</h3>
                  </div>

                  <div className="info">
                    <p>Last Month Revenue</p>
                    <h3>{`₦${vendor.last_month_revenue.toLocaleString()}`}</h3>
                  </div>
                </div>

                <div className="section__four">
                  <div className="info">
                    <p>Max. Revenue In a Month</p>
                    <h3>{`₦${vendor.highest_revenue_in_month.toLocaleString()} for ${vendor.month_with_highest
                      }`}</h3>
                  </div>

                  <div className="info">
                    <p>Last Seen</p>
                    <h3>{vendor.user.last_login_date}</h3>
                  </div>
                </div>

                <div className="section__five">
                  <div className="info">
                    <p>Last Transaction Time</p>
                    <h3>{vendor.last_transaction?.created_date || ''}</h3>
                  </div>

                  <div className="info">
                    <p>Vendor Created Date</p>
                    <h3>{vendor.created_date}</h3>
                  </div>
                </div>

                <div className="section__six">
                  <div className="info">
                    <p>Plan Name</p>
                    <h3 className={`plan ${plan.style}`}>{plan.value}</h3>
                  </div>

                  <div className="info">
                    <p>Plan Type</p>
                    <h3>{vendor.subscription?.plan.type || ''} </h3>
                  </div>
                </div>

                <div className="section__seven">
                  <div className="info">
                    <p>Plan Created Date</p>
                    <h3>{vendor.subscription?.created_date || ''}</h3>
                  </div>

                  <div className="info">
                    <p>Plan Expiration Date</p>
                    <h3>{vendor.subscription?.end_date || ''}</h3>
                  </div>
                </div>

                <div className="section__eight">
                  <div className="info">
                    <p>Invoice Usage Count</p>
                    <h3>{vendor.invoice_usage_count}</h3>
                  </div>

                  <div className="info">
                    <p>Storefront</p>
                    <a
                      href={`https://${vendor.slug}.taojaa.shop`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Storefront
                    </a>
                  </div>
                </div>
              </Card>
            </div>

            <div className="analytics">
              <Card className="header">
                <h1>Analytics</h1>
              </Card>

              <Card className="tabs__section">
                <Tabs
                  variant="soft-rounded"
                  colorScheme="purple"
                  align="center"
                  isFitted
                  size="sm"
                >
                  <TabList>
                    <Tab>Orders</Tab>
                    <Tab>Revenue</Tab>
                    <Tab>Products</Tab>
                    <Tab>Transactions</Tab>
                    <Tab>Total Customers</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Orders />
                    </TabPanel>
                    <TabPanel>
                      <Revenue />
                    </TabPanel>
                    <TabPanel>
                      <Products />
                    </TabPanel>
                    <TabPanel>
                      <Transactions />
                    </TabPanel>
                    <TabPanel>
                      <Customers />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <div className="app__flex"></div>
      )}
    </motion.div>
  );
};

export default React.memo(VendorProfile);
