import adminAvatar from '../assets/admin-avatar.png';
import adminLogo from '../assets/admin-logo.png';
import adminNotifications from '../assets/admin-notifications.png';
import avatar from '../assets/avatar.png';
import logo from '../assets/logo.png';
import successful from '../assets/successful.png';
import vendorProfile from '../assets/vendor-profile.png';

interface Image {
  adminAvatar: string;
  adminLogo: string;
  adminNotifications: string;
  avatar: string;
  logo: string;
  successful: string;
  vendorProfile: string;
}

export const Images: Image = {
  adminAvatar,
  adminLogo,
  adminNotifications,
  avatar,
  logo,
  successful,
  vendorProfile,
};
