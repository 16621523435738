'use client';

import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineEye } from 'react-icons/ai';
import { BiBlock } from 'react-icons/bi';
import { BsThreeDots, BsCheck2Circle } from 'react-icons/bs';
import { Avatar, IconButton } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { AdminContext } from '../../../../context/admin-context';
import { Card } from '../../../../exports/exports';
import './AdminGroup.scss';

const AdminGroup: React.FC = () => {
  const [seeAll, setSeeAll] = useState<boolean>(false);

  const ctx = useContext(AdminContext);

  const { admins, changeView, changeAdmin, blockAdmin, unblockAdmin } = ctx;

  const allLength = admins ? admins.length : 0;
  let allEndIndex = allLength > 12 ? 12 : allLength;

  const handleAccountSettings = (id: number) => {
    changeAdmin(id);
    changeView('settings');
  };

  const handleToggleAll = () => {
    // guard clause
    if (!admins) {
      setSeeAll(!seeAll);
      return;
    }

    const index = admins.length;

    switch (seeAll) {
      case true:
        allEndIndex = index > 12 ? 12 : index;
        setSeeAll(false);
        break;

      case false:
        allEndIndex = index;
        setSeeAll(true);
        break;

      default:
        break;
    }
  };

  return (
    <motion.div
      key="admins-group"
      className="admins-group"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <div className="all-admins-group">
        <div className="admins-header">
          <h1>All Admins</h1>

          <p className="see-all" onClick={handleToggleAll}>
            {`${seeAll ? 'See less' : 'See all'}`}
          </p>
        </div>

        <div className="all-admins">
          {admins &&
            admins.slice(0, allEndIndex).map((admin, index) => (
              <Card key={index} className="admins">
                <div className="image">
                  <Avatar
                    name={`${admin.firstname} ${admin.lastname}`}
                    src={`${process.env.REACT_APP_STORAGE_URL}/images/${admin.picture}`}
                    size="md"
                  />
                </div>

                <div className="info">
                  <div className="info-top">
                    <p>Admin Name</p>
                    <h3>{`${admin.firstname} ${admin.lastname}`}</h3>

                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<BsThreeDots />}
                        variant="ghost"
                        className="option"
                      />
                      <MenuList className="menu-list">
                        <MenuItem
                          icon={<AiOutlineEye />}
                          onClick={() => handleAccountSettings(admin.id)}
                          className="account-settings"
                        >
                          Account Settings
                        </MenuItem>
                        <MenuItem
                          icon={
                            admin.blocked ? <BsCheck2Circle /> : <BiBlock />
                          }
                          onClick={
                            admin.blocked
                              ? () => unblockAdmin(admin.id)
                              : () => blockAdmin(admin.id)
                          }
                          className={
                            admin.blocked ? 'unblock-admin' : 'block-admin'
                          }
                        >
                          {admin.blocked ? 'Unblock Admin' : 'Block Admin'}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>

                  <div className="info-bottom">
                    <p>Email</p>
                    <h3>{admin.email}</h3>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </div>
    </motion.div>
  );
};

export default AdminGroup;
