'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import Charts from 'react-apexcharts';
import { FiUsers } from 'react-icons/fi';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';

import { VendorsContext } from '../../../../../context/vendors-context';
import api from '../../../../../helpers/api';
import { CustomerType } from '../../../../../types/Vendor';
import { Skeleton } from '../../../../../exports/exports';
import formatValue from '../../../../../utils/formatValue';
import './Customers.scss';

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<CustomerType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(VendorsContext);

  const { vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);

      // Guard clause
      if (!vendor) {
        if (toast.isActive('vendor-not-found')) return;
        toast({
          id: 'vendor-not-found',
          title: 'Vendor not found',
          description: 'This Vendor does not exist.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      // Guard clause
      if (!vendor.subscription) {
        if (toast.isActive('sub-plan-not-found')) return;
        toast({
          id: 'sub-plan-not-found',
          title: 'Subscription plan not found',
          description: 'This Vendor does have an active subscription plan.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      const start_date = vendor.subscription.start_date.slice(0, 10);
      const end_date = vendor.subscription.end_date.slice(0, 10);

      try {
        const response = await api.get(
          `/vendors/customers-analytics?vendor_id=${vendor.id}&start_date=${start_date}&end_date=${end_date}`
        );

        // Guard clause
        if (!response) {
          setLoading(false);
          throw Error('No response from server.');
        }

        // Guard clause
        if (!response.data.data) {
          setLoading(false);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setLoading(false);
          return;
        }

        const data: CustomerType = response.data.data;
        setCustomers(data);
        setLoading(false);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchCustomers();
  }, [toast, vendor]);

  const CUSTOMERS_CHART = {
    options: {
      chart: { id: 'vendor_customers' },
      xaxis: { categories: customers?.ticks },
    },
    series: customers
      ? [
          {
            name: 'customers',
            data: customers?.customers,
          },
        ]
      : [],
    stroke: { curve: 'smooth' },
  };

  let customer_value, total_customers;

  // prettier-ignore
  total_customers = customers?.customers && customers.customers.reduce((prev, curr) => prev + curr, 0);

  const value = total_customers || 0;

  customer_value = formatValue(value);

  let loadingContent;

  switch (loading) {
    case true:
      loadingContent = <Skeleton />;
      break;

    case false:
      loadingContent = <div className="app__flex">Customers</div>;
      break;

    default:
      break;
  }

  return (
    <>
      {!loading && customers && (
        <div className="analytics__customers">
          <div className="info-section">
            <div className="icon-info">
              <div className="icon">
                <FiUsers />
              </div>

              <p>Total number of Customers</p>
            </div>

            <div className="stats-info">
              <div
                className={`stats ${
                  customers.customers_percentage < 50 ? 'decrease' : 'increase'
                }`}
              >
                <p>{`${customers?.customers_percentage}%`}</p>
                {customers.customers_percentage < 50 ? (
                  <BsArrowDownRight />
                ) : (
                  <BsArrowUpRight />
                )}
              </div>

              <p className="info">Vs last week</p>
            </div>
          </div>

          <p className="value">{`${customer_value}`}</p>

          <Charts
            options={CUSTOMERS_CHART.options}
            series={CUSTOMERS_CHART.series}
            type="line"
            width="100%"
            height="300"
          />
        </div>
      )}
      {!loading && !customers && loadingContent}
      {loading && loadingContent}
    </>
  );
};

export default Customers;
