'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FiUsers } from 'react-icons/fi';
import { BiCalendar } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import Charts from 'react-apexcharts';
import { useToast } from '@chakra-ui/react';

import { RevenueChart } from '../../../../types/Report';
import { Data, Users, Customers } from '../../../../types/Overview';
import { UserContext } from '../../../../context/user-context';
import api from '../../../../helpers/api';
import formatValue from '../../../../utils/formatValue';
import './Reports.scss';

const Reports: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [analytics, setAnalytics] = useState<null | Data>(null);
  // prettier-ignore
  const [totalCustomers, setTotalCustomers] = useState<null | Customers>(null);
  // prettier-ignore
  const [totalVendors, setTotalVendors] = useState<null | Users>(null);
  // prettier-ignore
  const [totalRevenue, setTotalRevenue] = useState<null | RevenueChart>(null);

  const ctx = useContext(UserContext);
  const { user } = ctx;

  const handleChange = useCallback((date: Date) => setStartDate(date), []);

  const toast = useToast();

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await api.get('/overview/analytics');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Data = response.data.data;
        setAnalytics(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    const fetchCustomerChart = async () => {
      try {
        const response = await api.get('/overview/customers-chart');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Customers = response.data.data;
        setTotalCustomers(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    const fetchVendorChart = async () => {
      try {
        const response = await api.get('/overview/users-chart');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Users = response.data.data;
        setTotalVendors(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    // eslint-disable-next-line
    const fetchRevenueChart = async () => {
      try {
        const response = await api.get('/overview/revenue-chart');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: RevenueChart = response.data.data;
        setTotalRevenue(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchAnalytics();
    fetchCustomerChart();
    fetchVendorChart();
    // fetchRevenueChart();
  }, [toast]);

  const CUSTOMERS_CHART = {
    options: {
      chart: { id: 'customers' },
      xaxis: { categories: totalCustomers?.labels },
    },
    series: totalCustomers
      ? [
          {
            name: 'total_customers_per_month',
            data: totalCustomers.customers,
          },
        ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const VENDORS_CHART = {
    options: {
      chart: { id: 'vendors' },
      xaxis: { categories: totalVendors?.labels },
    },
    series: totalVendors
      ? [
          {
            name: 'total_vendors_per_month',
            data: totalVendors.users,
          },
        ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const REVENUE_CHART = {
    options: {
      chart: { id: 'revenue' },
      xaxis: { categories: totalRevenue?.ticks },
    },
    series: totalRevenue
      ? [
          {
            name: 'total_revenue_per_month',
            data: totalRevenue?.revenues,
          },
        ]
      : [],
  };

  let customer_value, vendor_value, revenue_value;

  // prettier-ignore
  customer_value = analytics && formatValue(analytics.customers);
  // prettier-ignore
  vendor_value = analytics && formatValue(analytics.users);
  // prettier-ignore
  revenue_value = totalRevenue && formatValue(totalRevenue.total_revenue);

  return (
    <motion.main
      key="app__admin-reports"
      className="app__admin-reports"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <div className="reports-nav-info">
        <h1>Welcome {user?.firstname}</h1>

        <div className="date-picker">
          <DatePicker
            className="date"
            selected={startDate}
            onChange={handleChange}
            dateFormat="MMM, dd yyyy"
            todayButton="Today"
            popperPlacement="bottom-start"
          />

          <BiCalendar className="calendar" />
        </div>
      </div>

      <div className="reports-info-group">
        {analytics && (
          <div className="reports-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Customers</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${
                    analytics.customers_percentage < 50
                      ? 'decrease'
                      : 'increase'
                  }`}
                >
                  <p>{`${analytics.customers_percentage}%`}</p>
                  {analytics.customers_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${customer_value}`}</p>

            <Charts
              options={CUSTOMERS_CHART.options}
              series={CUSTOMERS_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {analytics && (
          <div className="reports-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Vendors</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${
                    analytics.users_percentage < 50 ? 'decrease' : 'increase'
                  }`}
                >
                  <p>{`${analytics.users_percentage}%`}</p>
                  {analytics.users_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${vendor_value}`}</p>

            <Charts
              options={VENDORS_CHART.options}
              series={VENDORS_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {totalRevenue && (
          <div className="reports-info">
            <div className="info-section">
              <p>Total Revenue</p>
            </div>

            <p className="value">{`$${revenue_value}`}</p>

            <Charts
              options={REVENUE_CHART.options}
              series={REVENUE_CHART.series}
              type="bar"
              width="100%"
              height="300"
            />
          </div>
        )}
      </div>
    </motion.main>
  );
};

export default Reports;
