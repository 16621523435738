'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import Charts from 'react-apexcharts';

import { VendorsContext } from '../../../../../context/vendors-context';
import api from '../../../../../helpers/api';
import { RevenueType } from '../../../../../types/Vendor';
import { Skeleton } from '../../../../../exports/exports';
import './Revenue.scss';

const Revenue: React.FC = () => {
  const [revenue, setRevenue] = useState<RevenueType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(VendorsContext);

  const { vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    const fetchRevenue = async () => {
      setLoading(true);

      // Guard clause
      if (!vendor) {
        if (toast.isActive('vendor-not-found')) return;
        toast({
          id: 'vendor-not-found',
          title: 'Vendor not found',
          description: 'This Vendor does not exist.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      // Guard clause
      if (!vendor.subscription) {
        if (toast.isActive('sub-plan-not-found')) return;
        toast({
          id: 'sub-plan-not-found',
          title: 'Subscription plan not found',
          description: 'This Vendor does have an active subscription plan.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      const start_date = vendor.subscription.start_date.slice(0, 10);
      const end_date = vendor.subscription.end_date.slice(0, 10);

      try {
        const response = await api.get(
          `/vendors/revenue-analytics?vendor_id=${vendor.id}&start_date=${start_date}&end_date=${end_date}`
        );

        // Guard clause
        if (!response) {
          setLoading(false);
          throw Error('No response from server.');
        }

        // Guard clause
        if (!response.data.data) {
          setLoading(false);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setLoading(false);
          return;
        }

        const data: RevenueType = response.data.data;
        setRevenue(data);
        setLoading(false);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchRevenue();
  }, [toast, vendor]);

  const REVENUE_CHART = {
    options: {
      chart: {
        id: 'vendor_revenue',
      },
      xaxis: { categories: revenue?.ticks },
    },
    series: revenue
      ? [
          {
            name: 'revenue',
            data: revenue?.revenues,
          },
        ]
      : [],
  };

  let loadingContent;

  switch (loading) {
    case true:
      loadingContent = <Skeleton />;
      break;

    case false:
      loadingContent = <div className="app__flex">Revenue</div>;
      break;

    default:
      break;
  }

  return (
    <>
      {!loading && revenue && (
        <div className="analytics__revenue">
          <div className="summary">
            <p className="heading">Total Revenue</p>
            <h3 className="value">{`₦${revenue.total_revenue.toLocaleString()}`}</h3>
          </div>

          <div className="chart">
            <Charts
              options={REVENUE_CHART.options}
              series={REVENUE_CHART.series}
              type="bar"
              width={'100%'}
              height={300}
            />
          </div>
        </div>
      )}
      {!loading && !revenue && loadingContent}
      {loading && loadingContent}
    </>
  );
};

export default Revenue;
