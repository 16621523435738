import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { EnterOTP, ForgotPassword, ResetPassword } from './exports/exports';
import { SignIn, Welcome } from './exports/exports';
import './App.css';

const Error404 = lazy(() => import('./pages/Error404/Error404'));
const Exports = lazy(() => import('./pages/Exports/Exports'));
const Overview = lazy(() => import('./pages/Overview/Overview'));
const Plans = lazy(() => import('./pages/Plans/Plans'));
const Reports = lazy(() => import('./pages/Reports/Reports'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Transactions = lazy(() => import('./pages/Transactions/Transactions'));
const Vendors = lazy(() => import('./pages/Vendors/Vendors'));

function App() {
  return (
    <Suspense fallback={<Welcome />}>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/otp" element={<EnterOTP />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/exports" element={<Exports />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/vendors" element={<Vendors />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
