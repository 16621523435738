'use client';

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field } from 'formik';
import { BiCalendar } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiOutlineEdit } from 'react-icons/ai';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { TableCaption, TableContainer } from '@chakra-ui/react';
import { Spinner, useToast, IconButton } from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { AllPlans, PlanDetail } from '../../../../types/Plan';
import { AddPlan, EditPlan } from '../../../../types/Plan';
import {
  AddPlanSchema,
  EditPlanSchema,
} from '../../../../utils/validationSchema';
import { UserContext } from '../../../../context/user-context';
import api from '../../../../helpers/api';

import './Plans.scss';

const initialAddValues: AddPlan = {
  group_name: '',
  name: '',
  price: '',
  discount: '',
  status: '',
};

const initialEditValues: EditPlan = {
  id: 0,
  group_name: '',
  name: '',
  price: 0,
  discount: 0,
  status: 0,
};

const Plans: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [allPlans, setAllPlans] = useState<AllPlans[] | null>(null);
  const [planDetail, setPlanDetail] = useState(initialEditValues);

  const [loading, setLoading] = useState<boolean>(false);
  const [addIsOpen, setAddIsOpen] = useState<boolean>(false);
  const [editIsOpen, setEditIsOpen] = useState<boolean>(false);

  const initialAddRef = React.useRef<HTMLInputElement>(null);
  const initiaEditRef = React.useRef<HTMLInputElement>(null);

  const ctx = useContext(UserContext);
  const { user } = ctx;

  const handleChange = useCallback((date: Date) => setSelectedDate(date), []);

  const toast = useToast();

  useEffect(() => {
    setLoading(true);

    const fetchAllPlans = async () => {
      try {
        const response = await api.get('/plans');
        setLoading(false);

        if (response.data.status === 'success') {
          const data: AllPlans[] = response.data.data;

          if (data === null || data.length === 0) {
            return setAllPlans(null);
          }

          return setAllPlans(data);
        }
      } catch (error: any) {
        if (error.response) {
          return toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        }

        return toast({
          id: 'response-error',
          title: 'Response Status Error',
          description: 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchAllPlans();
  }, [toast]);

  const refetchPlans = useCallback(async () => {
    try {
      const response = await api.get('/plans');

      if (!response) {
        setLoading(false);
        throw Error('No response from server.');
      }

      // Guard clause
      if (response.data.status !== 'success') {
        if (toast.isActive('response-error')) return;

        setLoading(false);
        return;
      }

      const data: AllPlans[] = response.data.data;

      if (data === null || data.length === 0) {
        setAllPlans(null);
        setLoading(false);
        return;
      }

      setAllPlans(data);
      setLoading(false);
    } catch (error: any) {
      toast({
        id: 'data-fetching-error',
        title: 'Data fetching failed',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }, [toast]);

  const handleOpenAddPlan = () => setAddIsOpen(true);
  const handleCloseAddPlan = () => setAddIsOpen(false);

  const handleAddPlan = useCallback(
    async (newPlan: AddPlan) => {
      try {
        const response = await api.post('/plans/create', newPlan);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to Create Plan',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('plan-created')) return;
        toast({
          id: 'plan-created',
          title: 'New Plan Created',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        refetchPlans();
      } catch (error: any) {
        toast({
          id: 'failed',
          title: 'Failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, refetchPlans]
  );

  const handleOpenEditPlan = () => setEditIsOpen(true);
  const handleCloseEditPlan = () => setEditIsOpen(false);

  const handleFetchDetail = useCallback(
    async (id: number) => {
      try {
        const response = await api.get(`/plans/details?id=${id}`);

        // Guard clause
        if (response.data.status === 'success') {
          const data: PlanDetail = response.data.data;

          const transformedData = {
            id: data.id,
            group_name: data.group_name,
            name: data.name,
            price: data.price,
            discount: data.discount,
            status: data.status,
          };

          setPlanDetail(transformedData);
          handleOpenEditPlan();
        }
      } catch (error: any) {
        if (error.response) {
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const handleEditPlan = useCallback(
    async (updatedValues: EditPlan) => {
      try {
        const response = await api.put('/plans/update', updatedValues);

        if (response.data.status === 'success') {
          if (toast.isActive('plan-updated')) return;
          toast({
            id: 'plan-updated',
            title: 'Plan Updated',
            description: response.data.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
        handleCloseEditPlan();
        refetchPlans();
      } catch (error: any) {
        if (error.response) {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to Update Plan',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }
        toast({
          id: 'failed',
          title: 'Failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, refetchPlans]
  );

  const handleDeletePlan = useCallback(
    async (id: number) => {
      try {
        const response = await api.get(`/plans/delete?id=${id}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to Delete Plan',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('plan-deleted')) return;
        toast({
          id: 'plan-deleted',
          title: 'Plan Deleted',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        refetchPlans();
      } catch (error: any) {
        toast({
          id: 'failed',
          title: 'Failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, refetchPlans]
  );

  const addPlanModal = (
    <Modal
      isOpen={addIsOpen}
      onClose={handleCloseAddPlan}
      initialFocusRef={initialAddRef}
      motionPreset="scale"
      scrollBehavior="inside"
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Formik
            initialValues={initialAddValues}
            validationSchema={AddPlanSchema}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);

              const group_name =
                values.group_name.charAt(0).toUpperCase() +
                values.group_name.slice(1);

              const name =
                values.name.charAt(0).toUpperCase() + values.name.slice(1);

              const updatedValues: AddPlan = {
                group_name,
                name,
                price: +values.price,
                discount: +values.discount,
                status: +values.status,
              };

              handleAddPlan(updatedValues);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="group_name">Group Name</label>
                      <div>
                        {errors.group_name && touched.group_name
                          ? errors.group_name
                          : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="group_name"
                      placeholder="Ex: Starter"
                      ref={initialAddRef}
                    />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="name">Name</label>
                      <div>
                        {errors.name && touched.name ? errors.name : ''}
                      </div>
                    </div>

                    <Field type="text" name="name" placeholder="Ex: 3 Months" />
                  </div>
                </div>

                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="price">Price</label>
                      <div>
                        {errors.price && touched.price ? errors.price : ''}
                      </div>
                    </div>

                    <Field type="text" name="price" placeholder="Ex: 12000" />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="discount">Discount</label>
                      <div>
                        {errors.discount && touched.discount
                          ? errors.discount
                          : ''}
                      </div>
                    </div>

                    <Field type="text" name="discount" placeholder="Ex: 0" />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="status">Status</label>
                      <div>
                        {errors.status && touched.status ? errors.status : ''}
                      </div>
                    </div>

                    <Field type="text" name="status" placeholder="Ex: 1 or 0" />
                  </div>
                </div>

                <ButtonGroup display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    disabled={isSubmitting}
                    width="full"
                    isLoading={isSubmitting}
                    loadingText="Submitting ..."
                  >
                    Add Plan
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={handleCloseAddPlan}
                    disabled={isSubmitting}
                    width="full"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const editPlanModal = (
    <Modal
      isOpen={editIsOpen}
      onClose={handleCloseEditPlan}
      initialFocusRef={initiaEditRef}
      motionPreset="scale"
      scrollBehavior="inside"
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Formik
            initialValues={planDetail}
            validationSchema={EditPlanSchema}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);

              const group_name =
                values.group_name.charAt(0).toUpperCase() +
                values.group_name.slice(1);

              const name =
                values.name.charAt(0).toUpperCase() + values.name.slice(1);

              const updatedValues: EditPlan = {
                id: values.id,
                group_name,
                name,
                price: values.price,
                discount: values.discount,
                status: values.status,
              };

              handleEditPlan(updatedValues);

              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ errors, values, handleChange, touched, isSubmitting }) => (
              <Form>
                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="group_name">Group Name</label>
                      <div>
                        {errors.group_name && touched.group_name
                          ? errors.group_name
                          : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="group_name"
                      value={values?.group_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="name">Name</label>
                      <div>
                        {errors.name && touched.name ? errors.name : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="name"
                      value={values?.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="price">Price</label>
                      <div>
                        {errors.price && touched.price ? errors.price : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="price"
                      value={values?.price.toString()}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="discount">Discount</label>
                      <div>
                        {errors.discount && touched.discount
                          ? errors.discount
                          : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="discount"
                      value={values?.discount.toString()}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="status">Status</label>
                      <div>
                        {errors.status && touched.status ? errors.status : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="status"
                      value={values?.status.toString()}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <ButtonGroup display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    disabled={isSubmitting}
                    width="full"
                    isLoading={isSubmitting}
                    loadingText="Updating ..."
                  >
                    Update Plan
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={handleCloseEditPlan}
                    disabled={isSubmitting}
                    width="full"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  return (
    <motion.main
      key="app__admin-plans"
      className="app__admin-plans"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      {addIsOpen && addPlanModal}
      {editIsOpen && editPlanModal}

      <div className="plans-nav-info">
        <h1>{`Welcome ${user?.firstname}`}</h1>

        <div className="date-picker">
          <DatePicker
            className="date"
            selected={selectedDate}
            onChange={handleChange}
            dateFormat="MMM, dd yyyy"
            todayButton="Today"
            popperPlacement="bottom-start"
          />

          <BiCalendar className="calendar" />
        </div>
      </div>

      <div className="plans-nav-message">
        <h3>Plans</h3>
        <p>{`You can add new members to the admin list by clicking on the add users button. Make sure the users you’re adding here are verified and up to the task.`}</p>
      </div>

      {loading && (
        <div className="app__flex w-full">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}

      {!loading && !allPlans && (
        <div className="app__flex w-full">Plans not found</div>
      )}

      {!loading && allPlans && (
        <div className="plans-group">
          {allPlans.map((plan, index) => (
            <div className="plans" key={`${index}-${plan.group_name}`}>
              <div className="group-name">
                <h3>{plan.group_name} Plan</h3>

                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<BsThreeDots />}
                    variant="ghost"
                    className="menu-button"
                  />
                  <MenuList className="menu-list">
                    <MenuItem
                      icon={<AiOutlinePlus />}
                      onClick={handleOpenAddPlan}
                      className="add-plan"
                    >
                      Add Plan
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>

              <div className="table-section">
                <TableContainer>
                  <Table variant="simple" colorScheme="purple" size="sm">
                    <TableCaption>{plan.group_name} Plan</TableCaption>
                    <Thead bgColor="gray.200" paddingY="1.5">
                      <Tr>
                        <Th>Plan ID</Th>
                        <Th>Group Name</Th>
                        <Th>Price</Th>
                        <Th>Discount Status</Th>
                        <Th>Duration</Th>
                        <Th>Options</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {plan.plans.map((data, index) => (
                        <Tr key={`${data.group_name}-${index}`}>
                          <Td>{data.plan_id}</Td>
                          <Td>{data.group_name}</Td>
                          <Td>{`₦${data.price.toLocaleString()}`}</Td>
                          <Td>{data.discount}</Td>
                          <Td>{data.name}</Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<BsThreeDots />}
                                variant="ghost"
                                className="menu-button"
                              />
                              <MenuList className="menu-list">
                                <MenuItem
                                  icon={<AiOutlineEdit />}
                                  onClick={() => handleFetchDetail(data.id)}
                                  className="edit-plan"
                                >
                                  Edit Plan
                                </MenuItem>
                                <MenuItem
                                  icon={<AiOutlineDelete />}
                                  onClick={() => handleDeletePlan(data.id)}
                                  className="delete-plan"
                                >
                                  Delete Plan
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ))}
        </div>
      )}
    </motion.main>
  );
};

export default Plans;
