import * as Yup from 'yup';

import { emailRegEx, passwordRegEx } from './regEX';

export const SigninSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegEx, 'Invalid email').required('Required'),
  password: Yup.string()
    .matches(passwordRegEx, 'Enter a valid password')
    .required('Required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRegEx, 'Enter a valid password')
    .required('Required'),
  confirm_password: Yup.string()
    .matches(passwordRegEx, 'Enter a valid password')
    .required('Required'),
});

export const AddMemberSchema = Yup.object().shape({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().matches(emailRegEx, 'Invalid email').required('Required'),
  role: Yup.string(),
});

export const AddPlanSchema = Yup.object().shape({
  group_name: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
  discount: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
});

export const EditPlanSchema = Yup.object().shape({
  group_name: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
  discount: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
});

export const AccountSettingSchema = Yup.object().shape({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string().matches(passwordRegEx, 'Enter a valid password'),
  confirm_password: Yup.string().matches(
    passwordRegEx,
    'Enter a valid password'
  ),
  calendly: Yup.string().required('Required'),
});

export const AddSubscriptionSchema = Yup.object().shape({
  plan_id: Yup.string().required('Required'),
  vendor_id: Yup.string().required('Required'),
});
