'use client';

import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from '@chakra-ui/react';

import { VendorsContext } from '../../../../../context/vendors-context';
import api from '../../../../../helpers/api';
import { TransactionType } from '../../../../../types/Vendor';
import { Skeleton } from '../../../../../exports/exports';
import './Transactions.scss';

const Transactions: React.FC = () => {
  // prettier-ignore
  const [transactions, setTransactions] = useState<TransactionType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(VendorsContext);

  const { vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);

      // Guard clause
      if (!vendor) {
        if (toast.isActive('vendor-not-found')) return;
        toast({
          id: 'vendor-not-found',
          title: 'Vendor not found',
          description: 'This Vendor does not exist.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      try {
        // prettier-ignore
        const response = await api.get(`/vendors/transactions?vendor_id=${vendor.id}`);

        // Guard clause
        if (!response) {
          setLoading(false);
          throw Error('No response from server.');
        }

        // Guard clause
        if (!response.data.data) {
          setLoading(false);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setLoading(false);
          return;
        }

        const data: TransactionType[] = response.data.data;
        setTransactions(data);
        setLoading(false);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchTransactions();
  }, [toast, vendor]);

  let loadingContent;

  switch (loading) {
    case true:
      loadingContent = <Skeleton />;
      break;

    case false:
      loadingContent = <div className="app__flex">Transactions</div>;
      break;

    default:
      break;
  }

  return (
    <>
      {!loading && transactions && (
        <div className="analytics__transactions">
          <div className="heading">
            <h1>Recent Transcations</h1>
            <p>See all</p>
          </div>

          <div className="table">
            <TableContainer>
              <Table variant="simple" colorScheme="purple" size="sm">
                <TableCaption>Recent Transactions</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Transaction type</Th>
                    <Th>Status</Th>
                    <Th>Amount</Th>
                    <Th>Payment method</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactions.map((data, index) => (
                    <Tr key={index}>
                      <Td>{data.created_date}</Td>
                      <Td>{data.type}</Td>
                      <Td>{data.status}</Td>
                      <Td>{`${
                        data.currency || 'NGN'
                      } ${data.amount.toLocaleString()}`}</Td>
                      <Td>{data.payment_method}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      {!loading && !transactions && loadingContent}
      {loading && loadingContent}
    </>
  );
};

export default Transactions;
