'use client';

import React, { useContext } from 'react';
import { motion } from 'framer-motion';

import { VendorsContext } from '../../../../context/vendors-context';
import { Welcome } from '../../../../exports/exports';
import VendorsGroup from './VendorsGroup';
import VendorProfile from './VendorProfile';
import './Vendors.scss';

const Vendors: React.FC = () => {
  const ctx = useContext(VendorsContext);

  const { view } = ctx;

  let content;

  switch (view) {
    case 'group':
      content = <VendorsGroup />;
      break;

    case 'profile':
      content = <VendorProfile />;
      break;

    default:
      content = <Welcome />;
      break;
  }

  return (
    <motion.main
      key="app__admin-vendors"
      className="app__admin-vendors"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      {content}
    </motion.main>
  );
};

export default Vendors;
