'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import User from '../types/User';
import { Admin } from '../types/Settings';
import { getUser, loggedIn, logout } from '../helpers/auth';
import api from '../helpers/api';

type Props = { children: React.ReactNode };

interface UserContextObj {
  user: User | null;
  handleLogout: () => void;
  isLoggedIn: boolean;
  activeAdmin: Admin | null;
  fetchAdmins: any;
}

export const UserContext = React.createContext<UserContextObj>({
  user: null,
  handleLogout: () => {},
  isLoggedIn: false,
  activeAdmin: null,
  fetchAdmins: null,
});

const UserContextProvider: React.FC<Props> = props => {
  const [user, setUser] = useState<User | null>(null);
  const [activeAdmin, setActiveAdmin] = useState<Admin | null>(null);

  const router = useNavigate();
  const isLoggedIn = loggedIn();

  const toast = useToast();

  const appLogout = useCallback(() => {
    logout();
    setUser(null);
    router('/signin');
  }, [router]);

  useEffect(() => {
    const validateToken = async (token: string | null) => {
      try {
        // eslint-disable-next-line
        const response = await api.get('/overview/analytics');
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.message === 'Unauthorized request') {
            appLogout();
          }
        }
      }
    };

    if (localStorage.getItem('token')) {
      validateToken(localStorage.getItem('token'));
    }

    const USER = getUser();
    setUser(USER);
  }, [appLogout]);

  const fetchAdmins = useCallback(async () => {
    try {
      const response = await api.get('/admins');

      if (!response) throw Error('No response from server.');

      // Guard clause
      if (response.data.status !== 'success') {
        if (toast.isActive('response-error')) return;
        toast({
          id: 'response-error',
          title: 'Response Status Error',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      const adminData: Admin[] = response.data.data;

      let match;

      if (user) {
        match = adminData.find(admin => admin.lastname === user.lastname);
      }

      if (match) setActiveAdmin(match);
    } catch (error: any) {
      toast({
        id: 'data-fetching-error',
        title: 'Data fetching failed',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }, [toast, user]);

  const handleLogout = useCallback(() => {
    appLogout();
  }, [appLogout]);

  const contextValue: UserContextObj = {
    user,
    handleLogout,
    isLoggedIn,
    activeAdmin,
    fetchAdmins,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
