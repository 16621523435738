'use client';

import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import OtpInput from 'react-otp-input';

import { Button, Card } from '../../../../exports/exports';
import { images } from '../../../../constants';
import './EnterOTP.scss';

interface Timer {
  min: string;
  sec: string;
}

const EnterOTP: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState<Timer>({ min: '', sec: '' });
  const [timeLeft, setTimeLeft] = useState<number>(300);

  function convertTimeLeft(seconds: number) {
    // converting remaining time to minute(s) & second(s)
    const min = `${Math.trunc(seconds / 60)}`.padStart(2, '0');
    const sec = `${seconds % 60}`.padStart(2, '0');

    // set timer state
    setTimer({ min, sec });
  }

  useEffect(() => {
    // exit early when timeLeft reaches 0
    if (timeLeft === 0) {
      convertTimeLeft(timeLeft);
      return;
    }

    convertTimeLeft(timeLeft);

    // save interval variable to clear the interval function when the component re-renders
    const tick = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(tick);
  }, [timeLeft]);

  return (
    <main className="app__enter-otp">
      <motion.div
        className="app__enter-otp-centered"
        key="app__enter-otp-centered"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delayChildren: 0.5,
        }}
      >
        <Image src={images.adminLogo} alt="admin-logo" />
        <Card className="app__enter-otp-card">
          <h1 className="heading">Enter OTP</h1>
          <p className="description">We sent a 6-digit code to your email.</p>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={props => <input {...props} />}
            containerStyle="otp-input-container"
            inputStyle="otp-input-box"
            shouldAutoFocus
          />

          <Button
            type="submit"
            btn_style="btn--primary"
            size="btn--large"
            color="btn--alpha"
          >
            Proceed
          </Button>

          <div className="bottom-section">
            <h5>{`${timer.min}:${timer.sec}`}</h5>
            <p>
              Didn&apos;t get a code? <span>Resend</span>
            </p>
          </div>
        </Card>
      </motion.div>
    </main>
  );
};

export default EnterOTP;
