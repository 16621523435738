'use client';

import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Button, ButtonGroup } from '@chakra-ui/react';

import { Card } from '../../../../exports/exports';
import './Exports.scss';
import { UserContext } from '../../../../context/user-context';


const Exports: React.FC = () => {

    const ctx = useContext(UserContext);
    const { user } = ctx;

    return (
        <motion.div
            key="account-settings"
            className="account-settings"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 1,
                ease: 'easeInOut',
                delayChildren: 0.5,
            }}
        >
            <>
                <Card className="update-form">
                    <form action={`${process.env.REACT_APP_APP_API}/exports`} method='POST'>
                        <div className="input-section">

                            <input type="hidden" name="email" value={user?.email} />

                            <div className="input-group">
                                <div className="form-info">
                                    <label htmlFor="type">Type</label>
                                </div>

                                <select placeholder='Select option' name='type'>
                                    <option value='email'>Email</option>
                                    <option value='phone'>Phone Numbers</option>
                                    <option value='all'>Email + Phone Numbers</option>
                                </select>
                            </div>

                            <div className="input-group">
                                <div className="form-info">
                                    <label htmlFor="date">Date Range</label>
                                </div>

                                <div className="from-date-picker">
                                    <input type="date" name="start_date" id="" />
                                </div>

                                <div className="to-date-picker">
                                    <input type="date" name="end_date" id="" />
                                </div>
                            </div>

                            <div className="input-group">
                                <div className="form-info">
                                    <label htmlFor="password">Password</label>
                                </div>

                                <input type="password" name="password" id="" />
                            </div>
                        </div>

                        <ButtonGroup display="flex" justifyContent="space-end">
                            <Button
                                type="submit"
                                variant="solid"
                                colorScheme="purple"
                                width="full"
                                size="sm"
                            >
                                Export Data
                            </Button>
                        </ButtonGroup>
                    </form>
                </Card>
            </>
        </motion.div>
    );
};

export default Exports;
