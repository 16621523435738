const date = new Date();

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

let day = date.getDate();
let month = months[date.getMonth()].slice(0, 3);
let year = date.getFullYear();

// This arrangement can be altered based on how we want the date's format to appear.
export const currentDate = `${month},${day} ${year}`;
export const jsonDate = date.toJSON().slice(0, 10);
export const localeDate = date.toLocaleDateString();

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
