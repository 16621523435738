'use client';

// prettier-ignore
import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { FiUsers, FiSearch } from 'react-icons/fi';
import { PiDotsThree } from 'react-icons/pi';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import Charts from 'react-apexcharts';
import { usePagination, Pagination } from 'pagination-react-js';

import {
  Analytics,
  Transaction,
  PaginationType,
} from '../../../../types/Transaction';
import { UserContext } from '../../../../context/user-context';
import api from '../../../../helpers/api';
import formatValue from '../../../../utils/formatValue';
import './Transactions.scss';

const Transactions: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [analytics, setAnalytics] = useState<null | Analytics>(null);
  const [transactions, setTransactions] = useState<null | Transaction[]>(null);
  const [pagination, setPagination] = useState<null | PaginationType>(null);

  const startAt = pagination?.start_at || 1;
  const perPage = pagination?.per_page || 10;
  const totalEntries = transactions?.length || 0;

  // prettier-ignore
  const { currentPage, entriesPerPage, entries } = usePagination(startAt, perPage);

  const inputRef = useRef<HTMLInputElement>(null);

  const ctx = useContext(UserContext);
  const { user } = ctx;

  const handleChange = useCallback((date: Date) => setStartDate(date), []);

  const toast = useToast();

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await api.get('/transactions/analytics');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Analytics = response.data.data;
        setAnalytics(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await api.get('/transactions');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (!response.data.success) {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Transaction[] = response.data.data;
        const pagination: PaginationType = response.data.pagination;

        setTransactions(data);
        setPagination(pagination);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchAnalytics();
    fetchTransactions();

    inputRef.current?.focus();
  }, [toast]);

  const TOTAL_TRANSACTION_CHART = {
    options: {
      chart: { id: 'number_of_total_transactions' },
      xaxis: { categories: analytics?.labels },
    },
    series: analytics
      ? [
          {
            name: 'transactions_per_month',
            data: analytics.transactions,
          },
        ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const TOTAL_TRANSACTION_VALUE_CHART = {
    options: {
      chart: { id: 'number_of_total_transactions_value' },
      xaxis: { categories: analytics?.labels },
    },
    series: analytics
      ? [
          {
            name: 'transactions_values_per_month',
            data: analytics.transactions_value,
          },
        ]
      : [],
    stroke: { curve: 'smooth' },
  };

  let total_transaction, total_transaction_value;

  total_transaction =
    analytics?.total_transaction && formatValue(analytics?.total_transaction);

  total_transaction_value =
    analytics?.total_transaction_value &&
    formatValue(analytics?.total_transaction_value);

  return (
    <motion.main
      key="app__admin-transactions"
      className="app__admin-transactions"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <div className="transaction-nav-info">
        <h1>Welcome {user?.firstname}</h1>

        <div className="date-picker">
          <DatePicker
            className="date"
            selected={startDate}
            onChange={handleChange}
            dateFormat="MMM, dd yyyy"
            todayButton="Today"
            popperPlacement="bottom-start"
          />

          <BiCalendar className="calendar" />
        </div>
      </div>

      <div className="transaction-info-group">
        {analytics && (
          <div className="transaction-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total Transaction</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${
                    analytics.total_transaction_percentage < 50
                      ? 'decrease'
                      : 'increase'
                  }`}
                >
                  <p>{`${analytics.total_transaction_percentage}%`}</p>
                  {analytics.total_transaction_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${total_transaction}`}</p>

            <Charts
              options={TOTAL_TRANSACTION_CHART.options}
              series={TOTAL_TRANSACTION_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {analytics && (
          <div className="transaction-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total Transaction Value</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${
                    analytics.total_transaction_value_percentage < 50
                      ? 'decrease'
                      : 'increase'
                  }`}
                >
                  <p>{`${analytics.total_transaction_value_percentage}%`}</p>
                  {analytics.total_transaction_value_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${total_transaction_value}`}</p>

            <Charts
              options={TOTAL_TRANSACTION_VALUE_CHART.options}
              series={TOTAL_TRANSACTION_VALUE_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}
      </div>

      <div className="transaction-transactions">
        <div className="control">
          <h1>Transactions</h1>

          <div className="search">
            <FiSearch />
            <input type="text" placeholder="Tap to search" ref={inputRef} />
          </div>
        </div>

        <TableContainer>
          <Table variant="simple" colorScheme="purple" size="sm">
            <TableCaption>Transactions</TableCaption>
            <Thead>
              <Tr>
                <Th>Order ID</Th>
                <Th>Transaction ID</Th>
                <Th>Store name</Th>
                <Th>Customer name</Th>
                <Th>Transaction reference</Th>
                <Th>Amount</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions &&
                transactions
                  .slice(entries.indexOfFirst, entries.indexOfLast)
                  .map((data, index) => (
                    <Tr key={index}>
                      <Td>{data.order_id}</Td>
                      <Td>{data.id}</Td>
                      <Td>{data.vendor ? data.vendor.name : 'null'}</Td>
                      <Td>{data.vendor ? data.vendor.user : 'null'}</Td>
                      <Td>{data.reference}</Td>
                      <Td>{`${
                        data.currency || 'NGN'
                      } ${data.amount.toLocaleString()}`}</Td>
                      <Td>{data.status}</Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>

      <div className="transaction-pagination">
        <Pagination
          entriesPerPage={entriesPerPage.get}
          currentPage={{ get: currentPage.get, set: currentPage.set }}
          totalEntries={totalEntries}
          offset={3}
          classNames={{
            wrapper: 'pagination',
            item: 'pagination-item',
            itemActive: 'pagination-item-active',
            navPrev: 'pagination-item nav-item',
            navNext: 'pagination-item nav-item',
            navStart: 'pagination-item nav-item',
            navEnd: 'pagination-item nav-item',
            navPrevCustom: 'pagination-item',
            navNextCustom: 'pagination-item',
          }}
          showFirstNumberAlways={true}
          showLastNumberAlways={true}
          navStart={<MdKeyboardDoubleArrowLeft />}
          navEnd={<MdKeyboardDoubleArrowRight />}
          navPrev={<MdKeyboardArrowLeft />}
          navNext={<MdKeyboardArrowRight />}
          navPrevCustom={{ steps: 5, content: <PiDotsThree /> }}
          navNextCustom={{ steps: 5, content: <PiDotsThree /> }}
        />
      </div>
    </motion.main>
  );
};

export default React.memo(Transactions);
