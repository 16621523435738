function formatValue(value: number) {
  let new_value: string;

  if (value && value < 100) {
    new_value = `${value}`;
  } else if (value && value < 1000) {
    new_value = `${value}`;
  } else if (value && value >= 1000) {
    new_value = `${value.toString().charAt(0)}K`;
  } else if (value && value >= 10000) {
    new_value = `${value.toString().slice(0, 2)}K`;
  } else if (value && value >= 100000) {
    new_value = `${value.toString().slice(0, 3)}K`;
  } else if (value && value >= 1000000) {
    new_value = `${value.toString().charAt(0)}M`;
  } else if (value && value >= 10000000) {
    new_value = `${value.toString().slice(0, 2)}M`;
  } else {
    new_value = `${value}`;
  }

  return new_value;
}

export default formatValue;
