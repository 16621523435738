'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

import { Admin } from '../types/Settings';
import api from '../helpers/api';

type Props = { children: React.ReactNode };

interface AdminContextObj {
  admin: Admin | null;
  admins: Admin[] | null;
  view: string;
  changeAdmin: (id: number) => void;
  changeView: (view: string) => void;
  clearAdmin: () => void;
  blockAdmin: (id: number) => void;
  unblockAdmin: (id: number) => void;
}

export const AdminContext = React.createContext<AdminContextObj>({
  admin: null,
  admins: null,
  view: 'group',
  changeAdmin: (id: number) => {},
  changeView: (view: string) => {},
  clearAdmin: () => {},
  blockAdmin: (id: number) => {},
  unblockAdmin: (id: number) => {},
});

const AdminContextProvider: React.FC<Props> = props => {
  const [admins, setAdmins] = useState<Admin[] | null>(null);
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [view, setView] = useState<string>('group');

  const toast = useToast();

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await api.get('/admins');

        // Guard clause
        if (response.status === 500) throw Error('Internal server error.');

        // Guard clause
        if (!response) throw Error('No response from server.');

        // Guard clause
        if (!response.data.data) {
          setAdmins([]);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Admin[] = response.data.data;
        setAdmins(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchAdmins();
  }, [toast]);

  const changeAdmin = (id: number) => {
    if (!admins) return;

    const foundAdmin = admins.find(admin => admin.id === id);

    foundAdmin ? setAdmin(foundAdmin) : setAdmin(null);
  };

  const changeView = (newView: string) => setView(newView);

  const clearAdmin = () => setAdmin(null);

  const blockAdmin = useCallback(
    async (id: number) => {
      try {
        const response = await api.get('/admins/block');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to block admin',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('admin-blocked')) return;
        toast({
          id: 'admin-blocked',
          title: 'Admin blocked successfully',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Failed to block admin',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const unblockAdmin = useCallback(
    async (id: number) => {
      try {
        const response = await api.get('/admins/unblock');

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to unblock admin',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('admin-unblocked')) return;
        toast({
          id: 'admin-unblocked',
          title: 'Admin unblocked successfully',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Failed to unblock admin',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const contextValue: AdminContextObj = {
    admin,
    admins,
    view,
    changeView,
    changeAdmin,
    clearAdmin,
    blockAdmin,
    unblockAdmin,
  };

  return (
    <AdminContext.Provider value={contextValue}>
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
