'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useToast, Image } from '@chakra-ui/react';
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineShoppingCart,
} from 'react-icons/ai';

import { VendorsContext } from '../../../../../context/vendors-context';
import api from '../../../../../helpers/api';
import { ProductType } from '../../../../../types/Vendor';
import { Skeleton } from '../../../../../exports/exports';
import './Products.scss';

const Products: React.FC = () => {
  const [products, setProducts] = useState<ProductType[] | null>(null);
  const [liked, setLiked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(VendorsContext);

  const { vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      // Guard clause
      if (!vendor) {
        if (toast.isActive('vendor-not-found')) return;
        toast({
          id: 'vendor-not-found',
          title: 'Vendor not found',
          description: 'This Vendor does not exist.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      try {
        // prettier-ignore
        const response = await api.get(`/vendors/products?vendor_id=${vendor.id}`);

        // Guard clause
        if (!response) {
          setLoading(false);
          throw Error('No response from server.');
        }

        // Guard clause
        if (!response.data.data) {
          setLoading(false);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setLoading(false);
          return;
        }

        const data: ProductType[] = response.data.data;
        setProducts(data);
        setLoading(false);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchProducts();
  }, [toast, vendor]);

  const handleToggle = () => setLiked(!liked);

  const handleAddToCart = () => {};

  let loadingContent;

  switch (loading) {
    case true:
      loadingContent = <Skeleton />;
      break;

    case false:
      loadingContent = <div className="app__flex">Products</div>;
      break;

    default:
      break;
  }

  return (
    <>
      {!loading && products && (
        <div className="analytics__products">
          <p className="heading">Products Available</p>

          <div className="products-group">
            {products &&
              products.map((product, index) => (
                <div key={index} className="product">
                  <div className="add-to-like" onClick={handleToggle}>
                    {liked ? <AiFillHeart /> : <AiOutlineHeart />}
                  </div>

                  <div className="product-image">
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_URL}/images/${product.images.main}`}
                      alt="product"
                    />
                  </div>

                  <p className="product-name">{product.name}</p>

                  <div className="price-and-cart">
                    <div className="price">
                      <p className="heading">Price</p>
                      <h3 className="value">{product.price}</h3>
                    </div>

                    <div className="cart" onClick={handleAddToCart}>
                      <AiOutlineShoppingCart />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {!loading && !products && loadingContent}
      {loading && loadingContent}
    </>
  );
};

export default Products;
