'use client';

import React from 'react';
import { Image } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { motion } from 'framer-motion';
import { RxLoop } from 'react-icons/rx';

import { Button, Card } from '../../../../exports/exports';
import { images } from '../../../../constants';
import './ForgotPassword.scss';

interface InitialValues {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const initialValues: InitialValues = { email: '' };

  function validateEmail(value: string) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  return (
    <main className="app__forgot-password">
      <motion.div
        className="app__forgot-password-centered"
        key="app__forgot-password-centered"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delayChildren: 0.5,
        }}
      >
        <Image src={images.adminLogo} alt="admin-logo" />
        <Card className="app__forgot-password-card">
          <h1 className="heading">Forgotten Password</h1>
          <p className="description">
            {`Oops. Don't worry, it happens to the best of us.`}
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              console.log({ values, actions });
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue }) => (
              <Form>
                <div className="form-info">
                  <label htmlFor="email">Email</label>
                  <div>{errors.email && touched.email ? errors.email : ''}</div>
                </div>

                <div className="input-group">
                  <Field
                    type="email"
                    name="email"
                    placeholder="someone@example.com"
                    validate={validateEmail}
                  />
                  <RxLoop onClick={() => setFieldValue('email', '')} />
                </div>

                <Button
                  type="submit"
                  btn_style="btn--primary"
                  size="btn--large"
                  color="btn--alpha"
                  disabled={isSubmitting}
                >
                  Proceed
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
      </motion.div>
    </main>
  );
};

export default ForgotPassword;
