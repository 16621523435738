'use client';

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FormControl, Radio, RadioGroup } from '@chakra-ui/react';
import { Spinner, VStack, useToast, Avatar } from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { AlertDialog, AlertDialogBody } from '@chakra-ui/react';
import { AlertDialogFooter, AlertDialogHeader } from '@chakra-ui/react';
import { AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { AlertDialogCloseButton } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { AiOutlinePlus, AiOutlineCloseSquare } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { Admin, Member, Role } from '../../../../types/Settings';
import { Card } from '../../../../exports/exports';
import api from '../../../../helpers/api';
// import { AddMemberSchema } from '../../../../utils/validationSchema';
import './RolesPermission.scss';

const initialValues: Member = {
  email: '',
  role: 1,
};

const updateRoleValues: Member = {
  email: '',
  role: 1,
};

const RolesPermission: React.FC = () => {
  const [members, setMembers] = useState<Member[] | null>(null);
  const [updateMember, setUpdateMember] = useState<Member | null>(null);
  const [responseData, setResponseData] = useState<Admin[] | null>(null);
  const [adminRoles, setAdminRoles] = useState<Role[] | null>(null);
  const [activeMember, setActiveMember] = useState<Member | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [popoverisOpen, setPopoverIsOpen] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const initialFocusRef = useRef<HTMLInputElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const toast = useToast();

  const fetchMembers = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get('/admins');

      // Guard clause
      if (response.data.status !== 'success') {
        if (toast.isActive('response-error')) return;
        toast({
          id: 'response-error',
          title: 'Response Status Error',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      const adminData: Admin[] = response.data.data;

      const transformedData: Member[] = [];

      if (adminData === null || adminData.length === 0) {
        setLoading(false);
        return;
      }

      setResponseData(adminData);

      // Transforming adminData from backend to fit frontend
      adminData.forEach(data => {
        let roleName: string;

        switch (data.role_id) {
          case 1:
            roleName = 'Administrator';
            break;

          case 2:
            roleName = 'Manager';
            break;

          case 3:
            roleName = 'Support';
            break;

          case 4:
            roleName = 'Vendor';
            break;

          case 5:
            roleName = 'Customer';
            break;

          default:
            roleName = '';
            break;
        }

        const newData: Member = {
          id: data.id,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          role: data.role_id,
          roleName,
        };

        transformedData.push(newData);
      });

      setMembers(transformedData);
      setLoading(false);
    } catch (error: any) {
      toast({
        id: 'data-fetching-error',
        title: 'Data fetching failed',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }, [toast]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await api.get('/roles');

      if (!response) throw Error('No response from server.');

      // Guard clause
      if (response.data.status !== 'success') {
        if (toast.isActive('response-error')) return;
        toast({
          id: 'response-error',
          title: 'Response Status Error',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      const responseData: Role[] = response.data.data;

      if (responseData === null || responseData.length === 0) {
        if (toast.isActive('null-data')) return;
        toast({
          id: 'null-data',
          title: 'Data array is empty',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      setAdminRoles(responseData);
    } catch (error: any) {
      toast({
        id: 'data-fetching-error',
        title: 'Data fetching failed',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchMembers();
    fetchRoles();
  }, [fetchMembers, fetchRoles]);

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const handleOpenAlert = (member: Member) => {
    setActiveMember(member);
    setAlertIsOpen(true);
  };

  const handleCloseAlert = () => {
    setActiveMember(null);
    setAlertIsOpen(false);
  };

  const handleClosePopover = () => setPopoverIsOpen(false);

  const handleOpenPopover = (member: Member) => {
    setUpdateMember(member);
    setPopoverIsOpen(true);
  };

  const handleSubmit = useCallback(
    async (crendentials: Member) => {
      // Guard clause

      try {
        const response = await api.post('/admins/add', crendentials);

        // Guard clause
        if (response.data.status === 'success') {
          if (toast.isActive('invite-sent')) return;
          toast({
            id: 'invite-sent',
            title: 'Invite Successful',
            description: response.data.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        }

        fetchMembers();
      } catch (error: any) {
        if (error.response) {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to Send Invite',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        toast({
          id: 'failed',
          title: 'Failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, fetchMembers]
  );

  const handleRemoveMember = useCallback(
    async (member: Member) => {
      // Guard clause
      if (!responseData) {
        if (toast.isActive('no-source-data')) return;
        toast({
          id: 'no-source-data',
          title: 'Failed to remove member',
          description: 'Failed to retrieve members from source',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      const foundUser = responseData.find(admin => admin.id === member.id);

      // Guard clause
      if (!foundUser) {
        if (toast.isActive('no-found-member')) return;
        toast({
          id: 'no-found-member',
          title: 'Failed to remove member',
          description: 'Failed to find member in database',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      try {
        const response = await api.put('/admins/remove', foundUser);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Failed to remove member',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('member-removed')) return;
        toast({
          id: 'member-removed',
          title: 'Member removed successfully',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        fetchMembers();
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Failed to remove member',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, responseData, fetchMembers]
  );

  const handleUpdateRole = useCallback(
    async (member: Member, role: number) => {
      // transforming frontend data to fit backend
      const updatedAdmin: any = { email: member.email, role };

      try {
        const response = await api.put('/admins/change-role', updatedAdmin);

        // Guard clause
        if (response.data.status === 'success') {
          toast({
            id: 'response-success',
            title: 'Successfull',
            description: response.data.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setPopoverIsOpen(false);
          fetchMembers();
          return;
        }
      } catch (error: any) {
        if (error.response) {
          return toast({
            id: 'response-error',
            title: 'Failed to Update Role',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        }

        toast({
          id: 'failed',
          title: 'Failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast, fetchMembers]
  );

  const alert = activeMember && (
    <AlertDialog
      isOpen={alertIsOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCloseAlert}
      motionPreset="scale"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove Member
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody>
            You are about to remove a member, which will not allow this person
            to login into your organization anymore.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCloseAlert}>
              Keep member
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleRemoveMember(activeMember)}
              ml={3}
            >
              Remove member
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  const inviteMemberModal = (
    <Modal
      isOpen={modalIsOpen}
      onClose={handleCloseModal}
      initialFocusRef={initialFocusRef}
      motionPreset="scale"
      scrollBehavior="inside"
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Formik
            initialValues={initialValues}
            // validationSchema={AddMemberSchema}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);

              const updatedValues: Member = {
                email: values.email,
                role: values.role,
              };

              handleSubmit(updatedValues);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ errors, values, touched, isSubmitting, handleChange }) => (
              <Form>
                <div className="mid-section">
                  <div className="form-info">
                    <label htmlFor="email">Email</label>
                    <div>
                      {errors.email && touched.email ? errors.email : ''}
                    </div>
                  </div>

                  <Field
                    type="email"
                    name="email"
                    placeholder="someone@example.com"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="bottom-section">
                  <h3>Role</h3>
                  <FormControl>
                    <RadioGroup
                      colorScheme="purple"
                      defaultValue={values.role.toString()}
                    >
                      {adminRoles &&
                        adminRoles.map((role, index) => (
                          <VStack key={index} spacing="0.5rem" align="stretch">
                            <Radio
                              value={role.id.toString()}
                              onChange={handleChange}
                              name={'role'}
                            >
                              <p className="font-semibold">{role.name}</p>
                            </Radio>
                            <p className="mb-2">
                              Permissions include: read-and-write access to
                              every feature of the dashboard, adding new admin
                              and members, and choosing roles for members.
                            </p>
                          </VStack>
                        ))}
                    </RadioGroup>
                  </FormControl>
                </div>

                <ButtonGroup display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    disabled={isSubmitting}
                    width="full"
                    isLoading={isSubmitting}
                    loadingText="Inviting ..."
                    size="sm"
                  >
                    Invite
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                    width="full"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const UpdateSubscriptionModal = (
    <Modal
      isOpen={popoverisOpen}
      onClose={handleClosePopover}
      motionPreset="scale"
      scrollBehavior="inside"
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update member role</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Formik
            initialValues={updateRoleValues}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);

              if (!updateMember) return;

              handleUpdateRole(updateMember, values.role);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="input-section">
                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="email">Role</label>
                      <div>
                        {errors.role && touched.role ? errors.role : ''}
                      </div>
                    </div>

                    <Field as="select" name="role" className="dropdown">
                      <option value="">Select Role</option>
                      {adminRoles &&
                        adminRoles.map((role, index) => (
                          <option key={`${index}-${role.id}`} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </Field>
                  </div>

                  <div className="input-group">
                    <div className="form-info">
                      <label htmlFor="email">Email</label>
                      <div>
                        {errors.email && touched.email ? errors.email : ''}
                      </div>
                    </div>

                    <Field
                      type="text"
                      name="email"
                      placeholder="someone@example.com"
                      disabled
                      value={updateMember?.email}
                    />
                  </div>
                </div>

                <ButtonGroup display="flex" justifyContent="space-between">
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="purple"
                    disabled={isSubmitting}
                    width="full"
                    isLoading={isSubmitting}
                    loadingText="Submitting ..."
                  >
                    Update Role
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={handleClosePopover}
                    disabled={isSubmitting}
                    width="full"
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  return (
    <>
      {modalIsOpen && inviteMemberModal}
      {popoverisOpen && UpdateSubscriptionModal}
      {alertIsOpen && activeMember && alert}
      <div className="roles-permission">
        <div className="horizontal"></div>
        <h3 className="heading">Users</h3>
        <h3 className="description">{`You can add new members to the admin list by clicking on the add users button. Make the users you’re adding here are verified and up to the task.`}</h3>

        <div className="modal-trigger">
          <Button
            variant="solid"
            colorScheme="purple"
            size="sm"
            leftIcon={<AiOutlinePlus />}
            onClick={handleOpenModal}
          >
            Add Users
          </Button>
        </div>

        {/* LOADING STATE */}
        {loading && (
          <div className="app__flex w-full">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        )}

        {!loading && members && (
          <Card className="members">
            {members.map((member, index) => (
              <div key={`${member.email}-${index}`} className="member">
                <div className="user-info">
                  <Avatar bg="gray.200" icon={<FiUser fontSize="1.5rem" />} />

                  <div className="info">
                    <h3>{`${member.firstname} ${member.lastname}`}</h3>
                    <p>{member.email}</p>
                  </div>
                </div>

                <div className="user-role">
                  <Button
                    variant="solid"
                    colorScheme="purple"
                    size="sm"
                    rightIcon={<BiChevronDown />}
                    className="popover-button"
                    onClick={handleOpenPopover.bind(this, member)}
                  >
                    {member.roleName}
                  </Button>

                  <div
                    className="remove-member"
                    onClick={() => handleOpenAlert(member)}
                  >
                    <AiOutlineCloseSquare />
                  </div>
                </div>
              </div>
            ))}
          </Card>
        )}
      </div>
    </>
  );
};

export default RolesPermission;
