import React from 'react';

import { Home, Overview } from '../../exports/exports';
import UserContextProvider from '../../context/user-context';

const OverviewPage = () => {
  return (
    <UserContextProvider>
      <Home view={<Overview />} link={'/'} />
    </UserContextProvider>
  );
};

export default OverviewPage;
