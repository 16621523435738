'use client';

import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { Checkbox } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { RxLoop } from 'react-icons/rx';
import { BiHide, BiShow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from '../../../exports/exports';
import { images } from '../../../constants';
import { SigninSchema } from '../../../utils/validationSchema';
import './SignIn.scss';

import api, { updateToken } from '../../../helpers/api';
import { login } from '../../../helpers/auth';

interface InitialValues {
  email: string;
  password: string;
}

interface Error {
  status: string;
  message: string;
  errors: object;
}

const SignIn: React.FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [error, setError] = useState<null | Error>(null);

  const router = useNavigate();

  const initialValues: InitialValues = { email: '', password: '' };

  const handleToggle = () => setIsHidden(!isHidden);

  const handleSubmit = async (crendentials: Object) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_AUTH_API}/api/v1/account/admin/login`,
        crendentials
      );
      if (response.data.status === 'success') {
        login(response.data.data.user);
        updateToken(response.data.data.token);
        setError(null);
        router('/');
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data);
      }
    }
  };

  return (
    <main className="app__sign-in">
      <motion.div
        className="app__sign-in-centered"
        key="app__sign-in-centered"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delayChildren: 0.5,
        }}
      >
        <Image src={images.adminLogo} alt="admin-logo" />
        <Card className="app__sign-in-card">
          <h1 className="heading">Welcome back</h1>
          <p className="description">Sign in to continue</p>
          {error && <p style={{ color: 'red' }}>{error.message}</p>}
          <Formik
            initialValues={initialValues}
            validationSchema={SigninSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue }) => (
              <Form>
                <div className="form-info">
                  <label htmlFor="email">Email</label>
                  <div>{errors.email && touched.email ? errors.email : ''}</div>
                </div>

                <div className="input-group">
                  <Field
                    type="email"
                    name="email"
                    placeholder="someone@example.com"
                  />
                  <RxLoop onClick={() => setFieldValue('email', '')} />
                </div>

                <div className="form-info">
                  <label htmlFor="password">Password</label>
                  <div>
                    {errors.password && touched.password ? errors.password : ''}
                  </div>
                </div>

                <div className="input-group">
                  <Field
                    type={isHidden ? 'password' : 'text'}
                    name="password"
                    placeholder="******"
                  />
                  {isHidden ? (
                    <BiShow onClick={handleToggle} />
                  ) : (
                    <BiHide onClick={handleToggle} />
                  )}
                </div>

                <Button
                  type="submit"
                  btn_style="btn--primary"
                  size="btn--large"
                  color="btn--alpha"
                  disabled={isSubmitting}
                >
                  Proceed
                </Button>
              </Form>
            )}
          </Formik>

          <div className="bottom-section">
            <Checkbox
              colorScheme="purple"
              onChange={() => setCheckBox(!checkBox)}
              size="sm"
            >
              Remember me
            </Checkbox>

            <p>Forgotten password</p>
          </div>
        </Card>
      </motion.div>
    </main>
  );
};

export default SignIn;
