'use client';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
// import { Raleway, Inter, Manrope } from 'next/font/google';

// const raleway = Raleway({ weight: ['400'], subsets: ['cyrillic'] });
// const inter = Inter({ weight: ['400'], subsets: ['latin'] });
// const manrope = Manrope({ weight: ['400'], subsets: ['latin'] });

const colors = { primary: { 900: '#8A50FC' } };

const fonts = {
  // body: manrope.style.fontFamily,
  // heading: inter.style.fontFamily,
};

const theme = extendTheme({ colors, fonts });

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
  );
}
