import React from 'react';

import { Home, Exports } from '../../exports/exports';
import UserContextProvider from '../../context/user-context';

const ExportsPage = () => {
  return (
    <UserContextProvider>
      <Home view={<Exports />} link={'exports'} />
    </UserContextProvider>
  );
};

export default ExportsPage;
