'use client';

import React, { useCallback, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { BiCalendar } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { UserContext } from '../../../../context/user-context';
import AdminContextProvider from '../../../../context/admin-context';

import RolesPermission from './RolesPermission';
import UserManagement from './UserManagement';
import PaymentMethod from './PaymentMethod';
import './Settings.scss';

const Settings: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());

  const ctx = useContext(UserContext);
  const { user } = ctx;

  const handleChange = useCallback((date: Date) => setStartDate(date), []);

  return (
    <motion.main
      key="app__admin-settings"
      className="app__admin-settings"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <div className="settings-nav-info">
        <h1>Welcome {user?.firstname}</h1>

        <div className="date-picker">
          <DatePicker
            className="date"
            selected={startDate}
            onChange={handleChange}
            dateFormat="MMM, dd yyyy"
            todayButton="Today"
            popperPlacement="bottom-start"
          />

          <BiCalendar className="calendar" />
        </div>
      </div>

      <h1 className="heading">Settings</h1>
      <p className="description">Manage your teams and preferences here</p>

      <Tabs
        variant="soft-rounded"
        colorScheme="purple"
        align="start"
        size="md"
        className="tabs__section"
      >
        <TabList>
          <Tab>Roles and Permission</Tab>
          <Tab onClick={ctx.fetchAdmins}>User Management</Tab>
          <Tab>Payment Method</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <RolesPermission />
          </TabPanel>
          <TabPanel>
            <AdminContextProvider>
              <UserManagement />
            </AdminContextProvider>
          </TabPanel>
          <TabPanel>
            <PaymentMethod />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </motion.main>
  );
};

export default Settings;
