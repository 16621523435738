import { IconType } from 'react-icons/lib';
import { GoHome } from 'react-icons/go';
import { BiNotepad } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { TbReport, TbNotes, TbSettings, TbDownload } from 'react-icons/tb';

interface Sidebar {
  icon: IconType;
  label: string;
  link: string;
}

export const SIDEBAR: Sidebar[] = [
  { icon: GoHome, label: 'Overview', link: '/' },
  { icon: BsPeople, label: 'Vendors', link: 'vendors' },
  { icon: BiNotepad, label: 'Transactions', link: 'transactions' },
  { icon: TbReport, label: 'Reports', link: 'reports' },
  { icon: TbDownload, label: 'Exports', link: 'exports' },
  { icon: TbNotes, label: 'Plans', link: 'plans' },
  { icon: TbSettings, label: 'Settings', link: 'settings' },
];
