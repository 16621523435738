'use client';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Avatar, AvatarBadge, Image, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiLogOut, FiSearch } from 'react-icons/fi';
import { MdOutlineMarkUnreadChatAlt } from 'react-icons/md';
import { AlertDialog, AlertDialogBody } from '@chakra-ui/react';
import { AlertDialogFooter, AlertDialogHeader } from '@chakra-ui/react';
import { AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { AlertDialogCloseButton } from '@chakra-ui/react';

import { UserContext } from '../../../context/user-context';
import { images } from '../../../constants';
import { SIDEBAR } from '../../../helpers/sidebar';
import './Home.scss';
// import VendorsContextProvider from '../../../context/vendors-context';
// import { Welcome, Overview, Vendors, Exports } from '../../../exports/exports';
// import { Transactions, Reports } from '../../../exports/exports';
// import { Plans, Settings } from '../../../exports/exports';

type Props = { view: React.ReactElement; link: string };

const Home: React.FC<Props> = props => {
  const [view, setView] = useState<string>(props.link);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);

  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const { handleLogout, isLoggedIn, user, activeAdmin } = ctx;

  const inputRef = useRef<HTMLInputElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setView(props.link);
    inputRef.current?.focus();
  }, [props.link]);

  const handleOpenAlert = () => setAlertIsOpen(true);
  const handleCloseAlert = () => setAlertIsOpen(false);

  // Guard clause
  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  const handleChange = (link: string) => {
    if (link === '/') {
      navigate('/');
    } else {
      navigate(`/${link}`);
    }
  };

  // let content;

  // switch (view) {
  //   case 'Overview':
  //     content = <Overview />;
  //     break;

  //   case 'Vendors':
  //     content = (
  //       <VendorsContextProvider>
  //         <Vendors />
  //       </VendorsContextProvider>
  //     );
  //     break;

  //   case 'Transactions':
  //     content = <Transactions />;
  //     break;

  //   case 'Reports':
  //     content = <Reports />;
  //     break;

  //   case 'Exports':
  //     content = <Exports />;
  //     break;

  //   case 'Plans':
  //     content = <Plans />;
  //     break;

  //   case 'Settings':
  //     content = <Settings />;
  //     break;

  //   default:
  //     content = <Welcome />;
  //     break;
  // }

  const alert = (
    <AlertDialog
      isOpen={alertIsOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCloseAlert}
      motionPreset="scale"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Logout
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody>Are you sure you want to logout?</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCloseAlert}>
              Keep me in
            </Button>
            <Button colorScheme="red" onClick={handleLogout} ml={3}>
              Yes I want to
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  return (
    <>
      {alertIsOpen && alert}
      <motion.main
        key="app__admin-home"
        className="app__admin-home"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delayChildren: 0.5,
        }}
      >
        <div className="app_admin-home-sidebar">
          <Image src={images.adminLogo} width={150} alt="admin-logo" />

          <div className="sidebar-menu">
            {SIDEBAR.map((item, index) => (
              <div
                key={index}
                className={`menu-item ${view === item.link ? 'active' : ''}`}
                onClick={() => handleChange(item.link)}
              >
                <item.icon />
                <p>{item.label}</p>
              </div>
            ))}
          </div>

          <div className="logout" onClick={handleOpenAlert}>
            <FiLogOut />
            <p>Logout</p>
          </div>
        </div>

        <div className="app_admin-home-main">
          <div className="main-nav">
            <div className="nav-search">
              <div className="input-search">
                <div className="search">
                  <FiSearch />
                  <input
                    type="text"
                    placeholder="Tap to search"
                    ref={inputRef}
                  />
                </div>

                <div className="notification">
                  <MdOutlineMarkUnreadChatAlt />
                </div>
              </div>

              <div className="avatar-info">
                <div className="avatar">
                  <Avatar
                    name={`${user?.firstname} ${user?.lastname}`}
                    src={`${process.env.REACT_APP_STORAGE_URL}/images/${activeAdmin?.picture}`}
                    size="sm"
                  >
                    <AvatarBadge boxSize="0.8em" bg="green.500" />
                  </Avatar>
                </div>

                <div className="info">
                  <div className="label">
                    <h2>{`${user?.firstname} ${user?.lastname}`}</h2>
                    <p>Admin</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-content">{props.view}</div>
        </div>
      </motion.main>
    </>
  );
};

export default Home;
