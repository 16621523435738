'use client';

import React from 'react';
import { Image } from '@chakra-ui/react'
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Welcome.scss';

const Welcome: React.FC = () => {
  return (
    <motion.main
      key="app__welcome-page"
      className="app__welcome-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <Image
        src={images.logo}
        alt="logo"
        width={150}
      />
    </motion.main>
  );
};

export default Welcome;
