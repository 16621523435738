'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Formik, Form, Field } from 'formik';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useToast, Spinner, Avatar } from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { BiHide, BiShow } from 'react-icons/bi';

import { AdminContext } from '../../../../context/admin-context';
import { Card } from '../../../../exports/exports';
import { AccountSettingSchema } from '../../../../utils/validationSchema';
import { UpdateAdmin, Admin } from '../../../../types/Settings';
import api from '../../../../helpers/api';
import './AccountSettings.scss';

interface InitialValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  password: string;
  confirm_password: string;
  calendly: string;
}

const initialValues: InitialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  confirm_password: '',
  calendly: '',
};

const AccountSettings: React.FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(AdminContext);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { admin, changeView, clearAdmin } = ctx;

  const toast = useToast();

  useEffect(() => {
    !admin ? setLoading(true) : setLoading(false);

    inputRef.current?.focus();
  }, [admin]);

  const handleGoBack = () => {
    clearAdmin();
    changeView('group');
  };

  const handleToggle = () => setIsHidden(!isHidden);

  const handleSubmit = useCallback(
    async (crendentials: UpdateAdmin) => {
      // Guard clause
      if (!admin) {
        if (toast.isActive('no-found-admin')) return;
        toast({
          id: 'no-found-admin',
          title: 'Admin Update Failed',
          description: 'Failed to find admin in database',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      // transforming frontend data to fit backend
      const updatedAdmin: Admin = {
        ...admin,
        firstname: crendentials.firstname,
        lastname: crendentials.lastname,
        email: crendentials.email,
        password: crendentials.password,
      };

      try {
        const response = await api.put('/admins/update', updatedAdmin);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Admin update failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        if (toast.isActive('admin-updated')) return;
        toast({
          id: 'admin-updated',
          title: 'Update Successful',
          description: response.data.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [admin, toast]
  );

  return (
    <motion.div
      key="account-settings"
      className="account-settings"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      {loading && (
        <div className="app__flex w-full">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}

      {!loading && !admin && (
        <div className="app__flex w-full">Admin not found</div>
      )}

      {!loading && admin && (
        <>
          <div className="back-button" onClick={handleGoBack}>
            <MdKeyboardArrowLeft />
          </div>

          <Card className="admin-summary">
            <div className="admin-info">
              <div className="image">
                <Avatar
                  name={`${admin.firstname} ${admin.lastname}`}
                  src={`${process.env.REACT_APP_STORAGE_URL}/images/${admin.picture}`}
                  size="md"
                />
              </div>

              <div className="info">
                <div className="info-top">
                  <p>Admin Name</p>
                  <h3>{`${admin.firstname} ${admin.lastname}`}</h3>
                </div>
                <div className="info-bottom">
                  <p>Email</p>
                  <h3>{admin.email}</h3>
                </div>
              </div>
            </div>
          </Card>

          <Card className="update-form">
            <Formik
              initialValues={initialValues}
              validationSchema={AccountSettingSchema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);

                const updatedValues: UpdateAdmin = {
                  id: admin.id,
                  firstname: values.firstname,
                  lastname: values.lastname,
                  phone: values.phone,
                  email: values.email,
                  password: values.password || admin.password,
                };

                handleSubmit(updatedValues);

                actions.setSubmitting(false);
              }}
            >
              {({
                errors,
                values,
                touched,
                isSubmitting,
                resetForm,
                handleChange,
              }) => (
                <Form>
                  <div className="input-section">
                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="firstname">Firstname</label>
                        <div>
                          {errors.firstname && touched.firstname
                            ? errors.firstname
                            : ''}
                        </div>
                      </div>

                      <Field
                        ref={inputRef}
                        type="text"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="lastname">Lastname</label>
                        <div>
                          {errors.lastname && touched.lastname
                            ? errors.lastname
                            : ''}
                        </div>
                      </div>

                      <Field
                        type="text"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-section">
                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="phone">Phone</label>
                        <div>
                          {errors.phone && touched.phone ? errors.phone : ''}
                        </div>
                      </div>

                      <Field
                        type="text"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="email">Email</label>
                        <div>
                          {errors.email && touched.email ? errors.email : ''}
                        </div>
                      </div>

                      <Field
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="input-section">
                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="password">Password</label>
                        <div>
                          {errors.password && touched.password
                            ? errors.password
                            : ''}
                        </div>
                      </div>

                      <div className="password-input">
                        <Field
                          type={isHidden ? 'password' : 'text'}
                          name="password"
                          value={values.password}
                          placeholder="******"
                        />
                        {isHidden ? (
                          <BiShow onClick={handleToggle} />
                        ) : (
                          <BiHide onClick={handleToggle} />
                        )}
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="confirm_password">
                          Confirm Password
                        </label>
                        <div>
                          {errors.confirm_password && touched.confirm_password
                            ? errors.confirm_password
                            : ''}
                        </div>
                      </div>

                      <div className="password-input">
                        <Field
                          type={isHidden ? 'password' : 'text'}
                          name="confirm_password"
                          value={values.confirm_password}
                          placeholder="******"
                        />
                        {isHidden ? (
                          <BiShow onClick={handleToggle} />
                        ) : (
                          <BiHide onClick={handleToggle} />
                        )}
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="form-info">
                        <label htmlFor="calendly">Calendly</label>
                        <div>
                          {errors.calendly && touched.calendly
                            ? errors.calendly
                            : ''}
                        </div>
                      </div>

                      <Field
                        type="text"
                        name="calendly"
                        value={values.calendly}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <ButtonGroup display="flex" justifyContent="space-between">
                    <Button
                      type="submit"
                      variant="solid"
                      colorScheme="purple"
                      disabled={isSubmitting}
                      width="full"
                      isLoading={isSubmitting}
                      loadingText="Saving ..."
                      size="sm"
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme="purple"
                      onClick={() => resetForm()}
                      disabled={isSubmitting}
                      width="full"
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>

            <p className="view-activity">View login activity</p>
          </Card>
        </>
      )}
    </motion.div>
  );
};

export default AccountSettings;
