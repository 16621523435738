import axios from 'axios';

let token: string | null = null;

if (typeof window !== 'undefined') {
  // Perform localStorage action
  token = localStorage.getItem('token');
}
const api = axios;

api.defaults.baseURL = process.env.REACT_APP_APP_API + '/api/v1';
api.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: 'application/json',
};

export function updateToken(token: string) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem('token', token);
}

export default api;
