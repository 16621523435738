'use client';

import React, { ChangeEvent, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { Field, Form, Formik } from 'formik';
import { AiOutlineEye, AiOutlineSearch } from 'react-icons/ai';
import { BiCalendar, BiBlock, BiUserCircle } from 'react-icons/bi';
import { BsThreeDots, BsCheck2Circle } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Avatar, Button, IconButton } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { formatDate } from '../../../../utils/currentDate';
import { VendorsContext } from '../../../../context/vendors-context';
import { Card } from '../../../../exports/exports';
import './VendorsGroup.scss';
import ReactPaginate from 'react-paginate';

const VendorsGroup: React.FC = () => {
  const [allStart, setAllStart] = useState<Date | null>(null);
  const [allEnd, setAllEnd] = useState<Date | null>(null);
  const [storeName, setStoreName] = useState<string>('');

  const ctx = useContext(VendorsContext);

  const { vendors, changeVendor, changeView, blockUser } = ctx;
  const { unblockUser, activateUser, deactivateUser } = ctx;

  const handleAllStartChange = (date: Date) => setAllStart(date);
  const handleAllEndChange = (date: Date) => setAllEnd(date);

  const handleViewProfile = (id: number) => {
    changeVendor(id);
    changeView('profile');
  };

  const handleReset = () => {
    setAllStart(null);
    setAllEnd(null);
    setStoreName('');

    ctx.useFilter('');
  };

  const handleFilterSearch = (storeName: string) => {
    const startDate = allStart && formatDate(allStart);
    const endDate = allEnd && formatDate(allEnd);

    let query = '';

    if (startDate) query = query.concat(`?start_date=${startDate}`);
    // prettier-ignore
    if (endDate) query = query.concat(`${startDate ? '&' : '?'}end_date=${endDate}`);
    // prettier-ignore
    if (storeName) query = query.concat(`${startDate || endDate ? '&' : '?'}keywords=${storeName}`);

    ctx.useFilter(query);
  };

  const handleFilterPlan = (query: string) => ctx.useFilter(`?plan=${query}`);

  return (
    <motion.div
      key="vendors-group"
      className="vendors-group"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <>
        <div className="all-vendors-group">
          <div className="all-vendors-header">
            <h1>All Vendors</h1>

            <div className="date-filters">
              <p className="reset-filter" onClick={handleReset}>
                Reset Filters
              </p>
              <div className="filter-menu">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<MdKeyboardArrowDown />}
                    className="menu-button"
                  >
                    Filter by plan
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      className="menu-item"
                      onClick={handleFilterPlan.bind(this, 'Free')}
                    >
                      Free
                    </MenuItem>
                    <MenuItem
                      className="menu-item"
                      onClick={handleFilterPlan.bind(this, 'Starter')}
                    >
                      Starter
                    </MenuItem>
                    <MenuItem
                      className="menu-item"
                      onClick={handleFilterPlan.bind(this, 'Professional')}
                    >
                      Professional
                    </MenuItem>
                    <MenuItem
                      className="menu-item"
                      onClick={handleFilterPlan.bind(this, 'Premium')}
                    >
                      Premium
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>

              <div className="from-date-picker">
                <DatePicker
                  className="date"
                  selected={allStart}
                  onChange={handleAllStartChange}
                  dateFormat="MMM, dd yyyy"
                  todayButton="Today"
                  popperPlacement="bottom-start"
                  placeholderText="Start date"
                />

                <BiCalendar className="calendar" />
              </div>

              <div className="to-date-picker">
                <DatePicker
                  className="date"
                  selected={allEnd}
                  onChange={handleAllEndChange}
                  dateFormat="MMM, dd yyyy"
                  todayButton="Today"
                  popperPlacement="bottom-start"
                  placeholderText="End date"
                />

                <BiCalendar className="calendar" />
              </div>

              <div className="search-input-group">
                <Formik
                  initialValues={{ storeName: '' }}
                  onSubmit={async (values, actions) => {
                    actions.setSubmitting(true);

                    // TODO: implement search query function
                    handleFilterSearch(storeName);

                    actions.setSubmitting(false);
                    actions.resetForm();
                  }}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      <Field
                        type="text"
                        name="storeName"
                        placeholder="Enter a store name"
                        value={storeName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setStoreName(e.target.value)
                        }
                      />

                      <Button
                        size="sm"
                        type="submit"
                        variant="solid"
                        colorScheme="purple"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        loadingText="Submitting ..."
                        rightIcon={<AiOutlineSearch />}
                      >
                        Search
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <div className="all-vendors">
            {vendors &&
              vendors.map((vendor, index) => (
                <Card key={index} className="vendors">
                  <div className="image">
                    <Avatar
                      name={vendor.user}
                      src={`${process.env.REACT_APP_STORAGE_URL}/stores/${vendor.logo}`}
                      size="md"
                    />
                  </div>

                  <div className="info">
                    <div className="info-top">
                      <p>Vendors name</p>
                      <h3>{vendor.user}</h3>

                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<BsThreeDots />}
                          variant="ghost"
                          className="option"
                        />
                        <MenuList className="menu-list">
                          <MenuItem
                            icon={
                              vendor.blocked ? <BsCheck2Circle /> : <BiBlock />
                            }
                            onClick={
                              vendor.blocked
                                ? () => unblockUser(vendor.id)
                                : () => blockUser(vendor.id)
                            }
                            className={
                              vendor.blocked ? 'unblock-user' : 'block-user'
                            }
                          >
                            {vendor.blocked ? 'Unblock User' : 'Block User'}
                          </MenuItem>
                          <MenuItem
                            icon={<BiUserCircle />}
                            onClick={
                              vendor.active
                                ? () => deactivateUser(vendor.id)
                                : () => activateUser(vendor.id)
                            }
                            className="activate-user"
                          >
                            {vendor.active
                              ? 'Deactivate User'
                              : 'Activate User'}
                          </MenuItem>
                          <MenuItem
                            icon={<AiOutlineEye />}
                            onClick={() => handleViewProfile(vendor.id)}
                            className="view-profile"
                          >
                            View Profile
                          </MenuItem>
                          <MenuItem
                            as="a"
                            href={`https://${vendor.slug}.taojaa.shop`}
                            icon={<AiOutlineEye />}
                            className="view-storefront"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Storefront
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>

                    <div className="info-middle">
                      <p>Vendors store name</p>
                      <h3>{vendor.name}</h3>
                    </div>

                    <div className="info-bottom">
                      <p onClick={() => handleViewProfile(vendor.id)}>
                        View Profile
                      </p>
                      <a
                        href={`https://${vendor.slug}.taojaa.shop`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Storefront
                      </a>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
          <div style={{ marginTop: 20, display: "flex", gap: 2, justifyContent: "center" }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={ctx.handlePageClick}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              pageCount={ctx.pagination?.pages}
              previousLabel="< previous"
            />
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default React.memo(VendorsGroup);
