import React from 'react';

import { Home, Settings } from '../../exports/exports';
import UserContextProvider from '../../context/user-context';

const SettingsPage = () => {
  return (
    <UserContextProvider>
      <Home view={<Settings />} link={'settings'} />
    </UserContextProvider>
  );
};

export default SettingsPage;
