'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useToast } from '@chakra-ui/react';

import { Vendor, VendorDetail } from '../types/Vendor';
import api from '../helpers/api';

type Props = { children: React.ReactNode };

interface VendorsContextObj {
  vendor: VendorDetail | null;
  vendors: Vendor[] | null;
  pagination: any,
  view: string;
  loading: boolean;
  changeVendor: (id: number) => void;
  changeView: (view: string) => void;
  clearVendor: () => void;
  blockUser: (id: number) => void;
  unblockUser: (id: number) => void;
  activateUser: (id: number) => void;
  deactivateUser: (id: number) => void;
  useFilter: (query: string) => void;
  handlePageClick: (event: any) => void;
}

export const VendorsContext = React.createContext<VendorsContextObj>({
  vendor: null,
  vendors: null,
  pagination: null,
  view: 'group',
  loading: false,
  changeVendor: (id: number) => { },
  changeView: (view: string) => { },
  clearVendor: () => { },
  blockUser: (id: number) => { },
  unblockUser: (id: number) => { },
  activateUser: (id: number) => { },
  deactivateUser: (id: number) => { },
  useFilter: (query: string) => { },
  handlePageClick: (event: any) => { }
});

const VendorsContextProvider: React.FC<Props> = props => {
  const [vendors, setVendors] = useState<Vendor[] | null>(null);
  const [vendor, setVendor] = useState<VendorDetail | null>(null);
  const [pagination, setPagination] = useState(null);
  const [view, setView] = useState<string>('group');
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {

    fetchAllVendors();
  }, [showBoundary, toast]);

  const fetchAllVendors = async (page = 1) => {
    try {
      const response = await api.get(`/vendors?page=${page}&perpage=${50}`);

      // Guard clause
      if (response.status === 500) throw Error('Internal server error.');

      // Guard clause
      if (!response) throw Error('No response from server.');

      // Guard clause
      if (!response.data.data) {
        setVendors([]);
        throw Error('Data is unavailable.');
      }

      // Guard clause
      if (response.data.status !== 'success') {
        if (toast.isActive('response-error')) return;
        toast({
          id: 'response-error',
          title: 'Response Status Error',
          description: response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        return;
      }

      const data: Vendor[] = response.data.data;
      setVendors(data);
      setPagination(response.data.pagination)
    } catch (error: any) {
      showBoundary(error);
      toast({
        id: 'data-fetching-error',
        title: 'Data fetching failed',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const changeVendor = (id: number) => {
    if (!vendors) return;

    const foundVendor = vendors.filter(vendor => vendor.id === id)[0];

    const fetchDetails = async () => {
      setLoading(true);

      try {
        // prettier-ignore
        const response = await api.get(`/vendors/details?vendor_id=${foundVendor.id}`);

        if (!response) {
          setLoading(false);
          throw new Error('No response from server.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          setLoading(false);

          if (toast.isActive('response-error')) return;

          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: VendorDetail = response.data.data;
        setVendor(data);
        setLoading(false);
      } catch (error: any) {
        showBoundary(error);
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchDetails();
  };

  const changeView = (newView: string) => setView(newView);

  const clearVendor = () => setVendor(null);

  const blockUser = useCallback((id: number) => { }, []);

  const unblockUser = useCallback((id: number) => { }, []);

  const activateUser = useCallback((id: number) => { }, []);

  const deactivateUser = useCallback((id: number) => { }, []);

  const useFilter = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/vendors${query}`);

        // Guard clause
        if (response.status === 500) throw Error('Internal server error.');

        // Guard clause
        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.data.length === 0) setVendors([]);

        // Guard clause
        if (response.data.data === null) throw Error('Data is unavailable.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Vendor[] = response.data.data;
        setVendors(data);
      } catch (error: any) {
        showBoundary(error);
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [showBoundary, toast]
  );

  const handlePageClick = useCallback((event: any) => {
    console.log(event.selected);
    let currentPage = event.selected + 1
    fetchAllVendors(currentPage)
  }, []);

  const contextValue: VendorsContextObj = {
    loading,
    view,
    vendor,
    vendors,
    pagination,
    changeView,
    changeVendor,
    clearVendor,
    blockUser,
    unblockUser,
    activateUser,
    deactivateUser,
    useFilter,
    handlePageClick,
  };

  return (
    <VendorsContext.Provider value={contextValue}>
      {props.children}
    </VendorsContext.Provider>
  );
};

export default VendorsContextProvider;
