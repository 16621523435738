export const loggedIn = () => {
  return localStorage.getItem('token') !== null ? true : false;
};

export const getUser = () => {
  const userRaw = localStorage.getItem('user') || '';
  const USER = userRaw !== '' ? JSON.parse(userRaw) : null;

  return USER;
};

export const login = (user: object) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const logout = () => {
  localStorage.clear();
};
