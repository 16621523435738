'use client';

import React from 'react';

import './Button.scss';

const BTN_STYLES: string[] = ['btn--primary', 'btn--secondary'];

const SIZES: string[] = ['btn--medium', 'btn--large', 'btn--mobile'];

const COLOR: string[] = ['btn--alpha', 'btn--beta'];

type Props = {
  children: React.ReactNode;
  type?: any;
  onClick?: () => void;
  btn_style?: string | any;
  size?: string | any;
  color?: string | any;
  disabled?: boolean;
};

const Button: React.FC<Props> = props => {
  const checkStyle = BTN_STYLES.includes(props.btn_style)
    ? props.btn_style
    : BTN_STYLES[0];

  const checkSize = SIZES.includes(props.size) ? props.size : SIZES[0];

  const checkColor = COLOR.includes(props.color) ? props.color : COLOR[0];

  return (
    <button
      type={props.type}
      className={`btn ${checkStyle} ${checkSize} ${checkColor}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
