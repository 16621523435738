'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import Charts from 'react-apexcharts';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';

import { VendorsContext } from '../../../../../context/vendors-context';
import api from '../../../../../helpers/api';
import { OrderType } from '../../../../../types/Vendor';
import { Skeleton } from '../../../../../exports/exports';
import './Orders.scss';

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<OrderType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const ctx = useContext(VendorsContext);

  const { vendor } = ctx;

  const toast = useToast();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);

      // Guard clause
      if (!vendor) {
        if (toast.isActive('vendor-not-found')) return;
        toast({
          id: 'vendor-not-found',
          title: 'Vendor not found',
          description: 'This Vendor does not exist.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });

        setLoading(false);
        return;
      }

      try {
        // prettier-ignore
        const response = await api.get(`/vendors/order-analytics?vendor_id=${vendor.id}`);

        // Guard clause
        if (!response) {
          setLoading(false);
          throw Error('No response from server.');
        }

        // Guard clause
        if (!response.data.data) {
          setLoading(false);
          throw Error('Data is unavailable.');
        }

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setLoading(false);
          return;
        }

        const data: OrderType = response.data.data;
        setOrders(data);
        setLoading(false);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Data fetching failed',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    fetchOrders();
  }, [toast, vendor]);

  const series = orders
    ? [
        orders.orders_today_percentage,
        orders.pending_orders_percentage,
        orders.delivered_orders_percentage,
        orders.cancelled_orders_percentage,
      ]
    : [];

  // prettier-ignore
  const labels = [ 'Order Today', 'Pending Orders', 'Delivered Orders', 'Cancelled Orders'];

  const ORDERS_CHART = { options: { labels }, series };

  let loadingContent;

  switch (loading) {
    case true:
      loadingContent = <Skeleton />;
      break;

    case false:
      loadingContent = <div className="app__flex">Orders</div>;
      break;

    default:
      break;
  }

  return (
    <>
      {!loading && orders && (
        <div className="analytics__orders">
          <div className="summary">
            <div className="order-today">
              <p className="heading">Order Today</p>
              <h3 className="value">{orders.orders_today}</h3>
            </div>

            <div className="vertical"></div>

            <div className="pending-orders">
              <p className="heading">Pending Orders</p>
              <h3 className="value">{orders.pending_orders}</h3>
            </div>

            <div className="vertical"></div>

            <div className="delivered-orders">
              <p className="heading">Delivered Orders</p>

              <div className="app__flex gap-2">
                <h3 className="value">{`${orders.delivered_orders}%`}</h3>
                <div
                  className={`stats ${
                    orders.delivered_orders_percentage < 50
                      ? 'decrease'
                      : 'increase'
                  }`}
                >
                  <p>{`${orders.delivered_orders_percentage}%`}</p>
                  {orders.delivered_orders_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>
              </div>
            </div>

            <div className="vertical"></div>

            <div className="cancelled-orders">
              <p className="heading">Cancelled Orders</p>
              <h3 className="value">{orders.cancelled_orders}</h3>
            </div>
          </div>

          <div className="chart">
            <Charts
              options={ORDERS_CHART.options}
              series={ORDERS_CHART.series}
              type="donut"
              width={'100%'}
              height={300}
            />
          </div>
        </div>
      )}
      {!loading && !orders && loadingContent}
      {loading && loadingContent}
    </>
  );
};

export default Orders;
