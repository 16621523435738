'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FiUsers } from 'react-icons/fi';
import { BiCalendar } from 'react-icons/bi';
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { TableCaption, TableContainer } from '@chakra-ui/react';
import { IconButton, useToast } from '@chakra-ui/react';
import Charts from 'react-apexcharts';

import { Data, Users, Customers } from '../../../../types/Overview';
import { Transactions, RecentlyActiveUser } from '../../../../types/Overview';
import { UserContext } from '../../../../context/user-context';
import api from '../../../../helpers/api';
import { formatDate } from '../../../../utils/currentDate';
import formatValue from '../../../../utils/formatValue';
import './Overview.scss';

const Overview: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [analytics, setAnalytics] = useState<null | Data>(null);
  const [users, setUsers] = useState<null | Users>(null);
  const [customers, setCustomers] = useState<null | Customers>(null);
  const [transactions, setTransactions] = useState<null | Transactions>(null);
  const [activeUsers, setActiveUsers] = useState<null | Users>(null);
  // prettier-ignore
  const [recentlyActiveUsers, setRecentlyActiveUsers] = useState<null | RecentlyActiveUser[]>(null);

  const ctx = useContext(UserContext);
  const { user } = ctx;

  const toast = useToast();

  const handleStartChange = (date: Date) => setStartDate(date);
  const handleEndChange = (date: Date) => setEndDate(date);

  const fetchAnalytics = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/analytics${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Data = response.data.data;
        setAnalytics(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const fetchUsers = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/users-chart${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Users = response.data.data;
        setUsers(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const fetchCustomers = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/customers-chart${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Customers = response.data.data;
        setCustomers(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const fetchTransactions = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/transactions-chart${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Transactions = response.data.data;
        setTransactions(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const fetchActiveUsers = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/active-users-chart${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== 'success') {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: Users = response.data.data;
        setActiveUsers(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  const fetchRecentlyActiveUsers = useCallback(
    async (query: string) => {
      try {
        const response = await api.get(`/overview/recently-active${query}`);

        if (!response) throw Error('No response from server.');

        // Guard clause
        if (response.data.status !== "success") {
          if (toast.isActive('response-error')) return;
          toast({
            id: 'response-error',
            title: 'Response Status Error',
            description: response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          return;
        }

        const data: RecentlyActiveUser[] = response.data.data;
        setRecentlyActiveUsers(data);
      } catch (error: any) {
        toast({
          id: 'data-fetching-error',
          title: 'Error fetching data',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    fetchAnalytics('');
    fetchUsers('');
    fetchCustomers('');
    fetchTransactions('');
    fetchActiveUsers('');
    fetchRecentlyActiveUsers('');
  }, [
    fetchAnalytics,
    fetchUsers,
    fetchCustomers,
    fetchTransactions,
    fetchActiveUsers,
    fetchRecentlyActiveUsers,
    toast,
  ]);

  const USERS_CHART = {
    options: {
      chart: { id: 'number_of_users' },
      xaxis: { categories: users?.labels },
    },
    series: users
      ? [
        {
          name: 'users_per_month',
          data: users.users,
        },
      ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const CUSTOMERS_CHART = {
    options: {
      chart: { id: 'number_of_customers' },
      xaxis: { categories: customers?.labels },
    },
    series: customers
      ? [
        {
          name: 'customers_per_month',
          data: customers.customers,
        },
      ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const TRANSACTION_CHART = {
    options: {
      chart: { id: 'number_of_transactions' },
      xaxis: { categories: transactions?.labels },
    },
    series: transactions
      ? [
        {
          name: 'transactions_per_month',
          data: transactions.transactions,
        },
      ]
      : [],
    stroke: { curve: 'smooth' },
  };

  const ACTIVE_USERS_CHART = {
    options: {
      chart: { id: 'number_of_active_users' },
      xaxis: { categories: activeUsers?.labels },
    },
    series: activeUsers
      ? [
        {
          name: 'active_users_per_month',
          data: activeUsers.users,
        },
      ]
      : [],
    stroke: { curve: 'smooth' },
  };

  let user_value, customer_value, transaction_value, active_users_value;

  // prettier-ignore
  user_value = analytics?.users && formatValue(analytics?.users);
  // prettier-ignore
  customer_value = analytics?.customers && formatValue(analytics?.customers);
  // prettier-ignore
  transaction_value = analytics?.transactions && formatValue(analytics?.transactions);
  // prettier-ignore
  active_users_value = analytics?.active_users && formatValue(analytics?.active_users);

  const handleFilterSearch = useCallback(() => {
    const formatStartDate = startDate && formatDate(startDate);
    const formatEndDate = endDate && formatDate(endDate);

    let query = '';

    if (formatStartDate) query = query.concat(`?start_date=${formatStartDate}`);
    // prettier-ignore
    if (formatEndDate) query = query.concat(`${startDate ? '&' : '?'}end_date=${formatEndDate}`);

    fetchAnalytics(query);
    fetchUsers(query);
    fetchCustomers(query);
    fetchTransactions(query);
    fetchActiveUsers(query);
    fetchRecentlyActiveUsers(query);
  }, [
    endDate,
    fetchActiveUsers,
    fetchAnalytics,
    fetchCustomers,
    fetchRecentlyActiveUsers,
    fetchTransactions,
    fetchUsers,
    startDate,
  ]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);

    fetchAnalytics('');
    fetchUsers('');
    fetchCustomers('');
    fetchTransactions('');
    fetchActiveUsers('');
    fetchRecentlyActiveUsers('');
  };

  return (
    <motion.main
      key="app__admin-overview"
      className="app__admin-overview"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        delayChildren: 0.5,
      }}
    >
      <div className="overview-nav-info">
        <h1>Welcome {user?.firstname}</h1>

        <div className="date-filters">
          {(!!startDate || !!endDate) && (
            <p className="reset-filter" onClick={handleReset}>
              Reset Filters
            </p>
          )}

          <div className="from-date-picker">
            <DatePicker
              className="date"
              selected={startDate}
              onChange={handleStartChange}
              dateFormat="MMM, dd yyyy"
              todayButton="Today"
              popperPlacement="bottom-start"
              placeholderText="Start date"
            />

            <BiCalendar className="calendar" />
          </div>

          <div className="to-date-picker">
            <DatePicker
              className="date"
              selected={endDate}
              onChange={handleEndChange}
              dateFormat="MMM, dd yyyy"
              todayButton="Today"
              popperPlacement="bottom-start"
              placeholderText="End date"
            />

            <BiCalendar className="calendar" />
          </div>

          <IconButton
            aria-label="filter by date"
            colorScheme="purple"
            icon={<AiOutlineSearch />}
            onClick={handleFilterSearch}
            size="sm"
          />
        </div>
      </div>

      <div className="overview-info-group">
        {users && (
          <div className="overview-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Users</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${analytics && analytics?.users_percentage < 50
                    ? 'decrease'
                    : 'increase'
                    }`}
                >
                  <p>{`${analytics?.users_percentage}%`}</p>
                  {analytics && analytics?.users_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${user_value}`}</p>

            <Charts
              options={USERS_CHART.options}
              series={USERS_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {customers && (
          <div className="overview-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Customers</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${analytics && analytics?.customers_percentage < 50
                    ? 'decrease'
                    : 'increase'
                    }`}
                >
                  <p>{`${analytics?.customers_percentage}%`}</p>
                  {analytics && analytics?.users_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${customer_value}`}</p>

            <Charts
              options={CUSTOMERS_CHART.options}
              series={CUSTOMERS_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {transactions && (
          <div className="overview-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Transactions</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${analytics && analytics?.transactions_percentage < 50
                    ? 'decrease'
                    : 'increase'
                    }`}
                >
                  <p>{`${analytics?.transactions_percentage}%`}</p>
                  {analytics && analytics?.users_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${transaction_value}`}</p>

            <Charts
              options={TRANSACTION_CHART.options}
              series={TRANSACTION_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}

        {activeUsers && (
          <div className="overview-info">
            <div className="info-section">
              <div className="icon-info">
                <div className="icon">
                  <FiUsers />
                </div>

                <p>Total number of Active Users</p>
              </div>

              <div className="stats-info">
                <div
                  className={`stats ${analytics && analytics?.users_percentage < 50
                    ? 'decrease'
                    : 'increase'
                    }`}
                >
                  <p>{`${analytics?.users_percentage}%`}</p>
                  {analytics && analytics?.users_percentage < 50 ? (
                    <BsArrowDownRight />
                  ) : (
                    <BsArrowUpRight />
                  )}
                </div>

                <p className="info">Vs last week</p>
              </div>
            </div>

            <p className="value">{`${active_users_value}`}</p>

            <Charts
              options={ACTIVE_USERS_CHART.options}
              series={ACTIVE_USERS_CHART.series}
              type="line"
              width="100%"
              height="300"
            />
          </div>
        )}
      </div>

      <div className="overview-transactions">
        <h1>Recently Active</h1>

        <TableContainer>
          <Table variant="simple" colorScheme="purple" size="sm">
            <TableCaption>Recently Active Users</TableCaption>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>store ID</Th>
                <Th>Name</Th>
                <Th>Last Seen</Th>
                <Th>Website</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recentlyActiveUsers &&
                recentlyActiveUsers.map((data, index) => (
                  <Tr key={index}>
                    <Td>{data.id}</Td>
                    <Td>{data.store_id}</Td>
                    <Td>{data.name}</Td>
                    <Td>{data.user?.last_login_date}</Td>
                    <Td><a
                      href={`https://${data.store_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Storefront
                    </a>
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </motion.main>
  );
};

export default React.memo(Overview);
